import { Injectable, NgZone } from '@angular/core';
import { Account } from "../models/account";

@Injectable({
  providedIn: 'root'
})
/**
 * Paddle overlay wrapper.
 */
export class PaddleService {


  constructor(private ngZone: NgZone) {
  }

  createSubscription(planId: string, accountId: string, email: string, onSuccess: () => void, onClose: () => void) {
    window.Paddle.Checkout.open({
      product: planId,
      email: email,
      marketingConsent: '0',
      allowQuantity: false,
      disableLogout: true,
      customData: {
        account_id: accountId
      },
      successCallback: () => {
        this.ngZone.run(onSuccess);
      },
      closeCallback: () => {
        this.ngZone.run(onClose)
      },
      method: 'overlay'
    });
  }

  /**
   * Invoke the Paddle overlay for updating subscription payment method.
   *
   * @param account
   */
  updatePaymentMethod(account: Account): Promise<boolean> {
    if (!account.isPaddle || !account.hasSubscription || !account.paddle_sub_update_url) {
      return Promise.reject(`No active Paddle subscription found.`);
    }
    return new Promise<boolean>((resolve) => {
      window.Paddle.Checkout.open({
        override: account.paddle_sub_update_url,
        successCallback: () => {
          this.ngZone.run(() => resolve(true));
        },
        closeCallback: () => {
          this.ngZone.run(() => resolve(false));
        },
        method: 'overlay'
      });
    });
  }

  /**
   * Invoke the Paddle overlay for updating subscription payment method.
   *
   * @param account
   */
  cancelSubscription(account: Account): Promise<boolean> {
    if (!account.isPaddle || !account.hasSubscription || !account.paddle_sub_cancel_url) {
      return Promise.reject(`No active Paddle subscription found.`);
    }
    return new Promise<boolean>((resolve) => {
      window.Paddle.Checkout.open({
        override: account.paddle_sub_cancel_url,
        successCallback: () => {
          this.ngZone.run(() => resolve(true));
        },
        closeCallback: () => {
          this.ngZone.run(() => resolve(false));
        },
        method: 'overlay'
      });
    });
  }

  /**
   * Paddle invoice checkout out URLs are opened in a standalone window, they are meant to be opened by the
   * customer directly and are not embeddable.
   *
   * @param checkoutUrl
   */
  payInvoice(checkoutUrl: string): boolean {
    const win = window.open(checkoutUrl, '_blank');
    return win !== null;
  }
}
