import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { AppUrls } from "../../app-urls";
import { finalize } from "rxjs";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  resetCodeSent = false;
  formGroup: FormGroup;
  errorMessage = '';
  busy = false;

  constructor(private api: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder) {
    this.formGroup = formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      resetCode: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      newPasswordConfirmation: ['', [Validators.required]],
    });
    this.route.queryParams.subscribe(params => {
      this.resetCodeSent = !!params['resetCodeSent'];
      this.formGroup.patchValue({
        email: params['email'] ?? '',
        resetCode: params['resetCode'] ?? ''
      });
    });
  }

  resetPassword() {
    this.busy = true;
    this.errorMessage = '';
    this.api.resetPassword(
      this.formGroup.value.email,
      this.formGroup.value.resetCode,
      this.formGroup.value.newPassword
    ).pipe(
      finalize(() => {
        this.busy = false;
        this.resetCodeSent = false;
      })
    ).subscribe({
      next: () => {
        this.router.navigate([AppUrls.Root, AppUrls.Login], {
          queryParams: {
            fromReset: true,
            email: this.formGroup.value.email
          }
        });
      },
      error: err => {
        if (err instanceof HttpErrorResponse) {
          if (err.error && 'message' in err.error) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = err.message;
          }
        } else {
          this.errorMessage = err.toString();
        }
      }
    });
  }

}
