import { PaddleInvoice } from "../models/paddle-invoice";
import { DateUtil } from "../utils/date-util";

export class InvoiceViewModel {

  id: string;
  status: string;
  badgeClass: string;
  issuedAt: string;
  dueAt: string;
  paidAt: string;
  totalAmountWithCurrency: string;

  constructor(private invoice: PaddleInvoice) {
    this.id = invoice.id;
    this.issuedAt = invoice.issued_at ? DateUtil.formatISODate(invoice.issued_at) : '';
    this.dueAt = invoice.due_at ? DateUtil.formatISODate(invoice.due_at) : '';
    this.paidAt = invoice.paid_at ? DateUtil.formatISODate(invoice.paid_at) : '';

    // Paddle's total is a string in cents
    const total = (parseInt(invoice.total)/100).toLocaleString();
    this.totalAmountWithCurrency = `${total} ${invoice.currency_code}`;

    if (invoice.status === 'paid') {
      this.status = 'paid';
      this.badgeClass = 'bg-success';
    } else if (invoice.status === 'unpaid') {
      this.status = 'unpaid';
      this.badgeClass = 'bg-primary';
    } else if (invoice.status === 'overdue') {
      this.status = 'unpaid (overdue)';
      this.badgeClass = 'bg-danger';
    } else {
      this.status = `other (${invoice.status})`;
      this.badgeClass = 'bg-light';
    }
  }

  get canCheckout(): boolean {
    return (this.invoice.status === 'unpaid' || this.invoice.status === 'overdue') && !!this.invoice.checkout_url;
  }

  get checkoutUrl(): string | undefined {
    return this.invoice.checkout_url;
  }
}
