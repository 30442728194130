import { Component } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppUrls } from "../../app-urls";
import { HttpErrorResponse } from "@angular/common/http";
import { finalize } from "rxjs";
import { PlausibleService } from "../../services/plausible.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  busy: boolean;
  email?: string;
  resendVerificationCodeButtonLabel: string;
  verificationCodeSent: boolean;
  verificationCode?: string;
  emailVerified: boolean;

  successMessage?: string;
  errorMessage?: string;

  formGroupEmail: FormGroup;
  formGroupEmailVerification: FormGroup;
  formGroupPassword: FormGroup;

  constructor(private api: ApiService,
              private auth: AuthService,
              private router: Router,
              private plausible: PlausibleService,
              formBuilder: FormBuilder) {
    this.formGroupEmail = formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.formGroupEmailVerification = formBuilder.group({
      verificationCode: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.formGroupPassword = formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPasswordConfirmation: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.formGroupPassword.addValidators(() => {
      if (this.formGroupPassword.value.newPassword === this.formGroupPassword.value.newPasswordConfirmation) {
        return null;
      } else {
        return {match_error: 'Passwords do not match'};
      }
    });
    this.busy = false;
    this.verificationCodeSent = false;
    this.emailVerified = false;
    this.resendVerificationCodeButtonLabel = 'Resend verification code';
  }

  signUp() {
    if (!this.formGroupEmail.valid) {
      return;
    }
    this.busy = true;
    this.errorMessage = undefined;
    this.successMessage = undefined;

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.api.createAccount(this.formGroupEmail.value.email, timezone).pipe(
      finalize(() => {
        this.busy = false;
      })
    ).subscribe({
      next: message => {
        this.plausible.trackEvent('Signup');
        this.successMessage = message;
        this.verificationCodeSent = true;
        this.email = this.formGroupEmail.value.email;
      },
      error: err => {
        this.errorMessage = this.getErrorMessage(err);
        this.verificationCodeSent = false;
      }
    });
  }

  verifyEmail() {
    if (!this.formGroupEmailVerification.valid || !this.email) {
      return;
    }

    this.busy = true;
    this.errorMessage = undefined;
    this.successMessage = undefined;
    this.api.verifyEmail(this.email, this.formGroupEmailVerification.value.verificationCode).pipe(
      finalize(() => {
        this.busy = false;
      })
    ).subscribe({
      next: message => {
        this.plausible.trackEvent('EmailVerified');
        this.successMessage = message;
        this.emailVerified = true;
        this.verificationCode = this.formGroupEmailVerification.value.verificationCode;
      },
      error: err => {
        this.errorMessage = this.getErrorMessage(err);
        this.emailVerified = false;
      }
    });
  }

  setPassword() {
    if (!this.formGroupPassword.valid || !this.email || !this.verificationCode) {
      return;
    }

    this.busy = true;
    this.errorMessage = undefined;
    this.successMessage = undefined;
    this.api.setPassword(this.email, this.verificationCode, this.formGroupPassword.value.newPassword).pipe(
      finalize(() => {
        this.busy = false;
      })
    ).subscribe({
      next: (response: { message: string, access_token: string }) => {

        // password was set successfully, and we have an access token
        this.successMessage = response.message; // user won't see it

        // let auth service know about the access token
        this.auth.setAccessToken(response.access_token);
        this.auth.refreshAccount()
          .then(() => { // required to set state to authenticate
            return this.router.navigate([AppUrls.Root, AppUrls.Dashboard], {
              queryParams: {
                fromSignup: true
              }
            });
          })
          .catch(err => {
            this.errorMessage = this.getErrorMessage(err);
          });
      },
      error: err => {
        this.errorMessage = this.getErrorMessage(err);
      }
    });
  }

  resendVerificationCode() {
    if (!this.email) {
      return;
    }
    this.errorMessage = undefined;
    this.successMessage = undefined;
    this.api.resendVerificationMail(this.email).subscribe({
      next: (message) => {
        this.resendVerificationCodeButtonLabel = 'Sent!';
        this.verificationCodeSent = true;
        this.successMessage = message;
        setTimeout(() => {
          this.resendVerificationCodeButtonLabel = 'Resend verification code';
        }, 2500);
      },
      error: (err) => {
        this.verificationCodeSent = false;
        this.errorMessage = this.getErrorMessage(err);
      }
    });
  }

  private getErrorMessage(err: any): string {
    if (err instanceof HttpErrorResponse) {
      if (err.error && 'message' in err.error) {
        return err.error.message;
      } else {
        return err.message;
      }
    } else {
      return err.toString();
    }
  }
}
