import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, AuthState } from "../services/auth.service";
import { AppUrls } from "../app-urls";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean | UrlTree>((resolve) => {
      if (this.auth.state.getValue() === AuthState.SIGNED_OUT) {
        resolve(this.router.createUrlTree([AppUrls.Login]));
      } else if (this.auth.state.getValue() === AuthState.SIGNED_IN) {
        resolve(this.checkAccessToRoute(route));
      } else {
        // intermediate state, where auth service is checking token validity with the backend
        // --> wait for definitive answer
        const subscription = this.auth.state.subscribe((state) => {
          if (state === AuthState.SIGNED_IN) {
            resolve(this.checkAccessToRoute(route));
          } else if (state === AuthState.SIGNED_OUT) {
            subscription.unsubscribe();
            resolve(this.router.createUrlTree([AppUrls.Login]));
          }
        });
      }
    });
  }

  private checkAccessToRoute(route: ActivatedRouteSnapshot): boolean {
    const requiredRoles = route.data['roles'] as string[];
    if (!requiredRoles) {
      // no required roles: authenticated used is enough
      return true;
    }

    // check if user has a role
    const role = this.auth.account.value?.portal_role;
    if (!role) {
      console.warn(`Not allowed to access route`);
      return false; // some role required, but user doesn't have any
    }

    // access is granted if user has any one of the required roles
    const granted = requiredRoles.includes(role);
    if (!granted) {
      console.warn(`Not allowed to access route`);
    }
    return granted;
  }

}
