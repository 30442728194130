/**
 * Return the flag emoji corresponding to the given ISO country code
 *
 * TODO: what happens if the country code does not exist... bogus?
 *
 * @param countryCode
 */
export function getFlagEmoji(countryCode: string): string {
  const codePoints = countryCode.toUpperCase().split('').map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
