import { Component } from '@angular/core';

@Component({
  selector: 'app-purchase-completed',
  templateUrl: './purchase-completed.component.html',
  styleUrls: ['./purchase-completed.component.scss']
})
export class PurchaseCompletedComponent {

  constructor() {
  }

}
