<div id="layoutAuthentication">
  <div id="layoutAuthentication_content">
    <main>
      <div class="container-xl px-4 mt-10">
        <div class="row justify-content-center">
          <div class="col-lg-5">

            <a href="https://strich.io">
              <img src="assets/img/strich-logo-navbar.svg" width="139" height="30" alt="STRICH Logo">
            </a>

            <div class="card shadow-lg border-0 rounded-lg mt-4">
              <div class="card-header justify-content-center">
                <h1 class="fw-light my-2">Sign In to Customer Portal</h1>
              </div>
              <div class="card-body">

                <div *ngIf="fromReset" class="alert alert-success">
                  You've successfully set a new password for your account. Use it to sign in.
                </div>

                <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                  {{ errorMessage }}
                </div>

                <form>
                  <div class="mb-3">
                    <label class="small mb-1" for="inputEmailAddress">Email</label>
                    <input class="form-control" id="inputEmailAddress" name="email" type="email" autocomplete="email" placeholder="user@example.com"
                           [(ngModel)]="email"
                      />
                  </div>
                  <div class="mb-3">
                    <label class="small mb-1" for="inputPassword">Password</label>
                    <input class="form-control" id="inputPassword" name="password" type="password" autocomplete="current-password"
                           [(ngModel)]="password"/>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mt-4 mb-0">
                    <a class="small" routerLink="/forgot-password">Forgot Password?</a>
                    <button class="btn btn-primary" type="submit"
                            [routerLink]="" (click)="signIn()" [disabled]="busy">Sign in</button>
                  </div>
                </form>
              </div>
              <div class="card-footer text-center">
                <div class="small">
                  <a routerLink="/register">Need an account? Sign up!</a>
                </div>
                <div class="tiny">
                  {{ appVersion }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div id="layoutAuthentication_footer">
    <app-footer></app-footer>
  </div>
</div>
