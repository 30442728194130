import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { HttpErrorResponse } from "@angular/common/http";
import { AppUrls } from "../../app-urls";
import { Subscription } from "rxjs";
import { VersionService } from "../../services/version.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  fromReset = false;
  email = '';
  password = '';
  busy = false;
  errorMessage: string | undefined = undefined;
  appVersion: string;

  private accountSubscription?: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private auth: AuthService,
              version: VersionService) {
    this.appVersion = version.appVersion();
  }

  ngOnInit(): void {
    this.busy = false;
    this.route.queryParams.subscribe(params => {
      this.email = params['email'] ?? '';
      this.fromReset = !!params['fromReset'];
    });
    this.accountSubscription = this.auth.account.subscribe({
      next: account => {
        if (account) {
          this.router.navigate([AppUrls.Root, AppUrls.Dashboard], {replaceUrl: true});
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
      this.accountSubscription = undefined;
    }
  }

  signIn(): void {
    this.busy = true;
    this.errorMessage = undefined;
    this.auth.signIn(this.email, this.password)
      .catch(err => {
        if (err instanceof HttpErrorResponse && err.error && 'message' in err.error) {
          // use API-supplied error message if available
          this.errorMessage = err.error.message;
        } else {
          // otherwise show an opaque error message
          this.errorMessage = 'Failed to sign in, please try again later.';
        }
      })
      .finally(() => {
        this.busy = false;
        this.fromReset = false;
      });
  }
}
