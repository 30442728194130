<div id="layoutError">
  <div id="layoutError_content">
    <main>
      <div class="jumbotron d-flex align-items-center min-vh-100">
        <div class="container">
          <h1 class="display-4">Page not found</h1>
          <p class="lead">The requested page was not found.</p>
          <p class="lead">
            <a class="text-arrow-icon" routerLink="/">
              <i-feather class="ms-0 me-1" name="arrow-left"></i-feather>
              Return to home
            </a>
          </p>
        </div>
      </div>
    </main>
  </div>
  <div id="layoutError_footer">
    <app-footer></app-footer>
  </div>
</div>
