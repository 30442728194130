// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://reryamp6hu.eu-west-1.awsapprunner.com/api/v1', // DEV (AWS)
  //apiUrl: 'https://cuzdd2xikp.eu-west-1.awsapprunner.com/api/v1', // PROD (AWS)
  //apiUrl: 'http://localhost:5000/api/v1', // Local development
  paddle: {
    vendorId: '8877',
    sandbox: true,
    subscriptionPlanIds: {
      pro: '37917',
      basic: '37915'
    }
  },
  helpScout: {
    beaconId: 'a3f4e733-e25c-474f-be3e-375959767b9a'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
