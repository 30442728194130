import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { Router } from "@angular/router";
import { AppRoutingModule } from './app-routing.module';

import { JwtModule } from "@auth0/angular-jwt";

// Feather icons
import { IconsModule } from "./icons/icons.module";

// Components
import { AppComponent } from './components/app/app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { BillingComponent } from './components/billing/billing.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FooterComponent } from './components/footer/footer.component';
import { TopnavComponent } from './components/topnav/topnav.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { SecurityComponent } from './components/security/security.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { PurchaseCompletedComponent } from './components/purchase-completed/purchase-completed.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { CreateLicenseKeyComponent } from './components/create-license-key/create-license-key.component';
import { LicenseKeysComponent } from './components/license-keys/license-keys.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { CreateSubscriptionComponent } from './components/create-subscription/create-subscription.component';
import { SupportComponent } from './components/support/support.component';
import { UsageComponent } from './components/usage/usage.component';

// Service
import { AuthService } from "./services/auth.service";

// Interceptors
import { AuthenticationErrorInterceptor } from "./interceptors/authentication-error.interceptor";

import { environment } from "../environments/environment";


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    RegisterComponent,
    BillingComponent,
    NotFoundComponent,
    ForgotPasswordComponent,
    FooterComponent,
    TopnavComponent,
    SidenavComponent,
    SecurityComponent,
    NotificationsComponent,
    ResetPasswordComponent,
    PurchaseCompletedComponent,
    SubscriptionComponent,
    CreateLicenseKeyComponent,
    LicenseKeysComponent,
    PrivacyPolicyComponent,
    CreateSubscriptionComponent,
    SupportComponent,
    UsageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IconsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
        },
        allowedDomains: [new URL(environment.apiUrl).host],
        disallowedRoutes: [`${environment.apiUrl}/api/v1/auth/token`]
      },
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (router: Router, auth: AuthService) => {
        return new AuthenticationErrorInterceptor(router, auth);
      },
      multi: true,
      deps: [Router, AuthService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
