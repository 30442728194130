import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Account } from "../models/account";
import { PaymentInfo } from "../models/payment-info";
import { PaddleUserInfo } from "../models/paddle-user-info";
import { SubscriptionUsage } from "../models/subscription-usage";
import { PaddleInvoice } from "../models/paddle-invoice";
import { ScanStats } from "../models/scan-stats";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  authToken(email: string, password: string): Observable<string> {
    return this.http.post<{ access_token: string }>(`${environment.apiUrl}/auth/token`,
      {
        email,
        password
      }
    ).pipe(map(r => r.access_token));
  }

  getAccountInfo(): Observable<Account> {
    return this.http.get<{ [key: string]: any }>(`${environment.apiUrl}/account/me`).pipe(
      map(v => new Account(v))
    );
  }

  initiatePasswordReset(email: string): Observable<string> {
    return this.http.post<{ message: string }>(`${environment.apiUrl}/auth/initiate-password-reset`,
      {
        email: email
      }
    ).pipe(map(r => r.message));
  }

  resetPassword(email: string, resetCode: string, newPassword: string): Observable<string> {
    return this.http.post<{ message: string }>(`${environment.apiUrl}/auth/reset-password`,
      {
        email: email,
        reset_code: resetCode,
        new_password: newPassword
      }
    ).pipe(map(r => r.message));
  }

  changePassword(oldPassword: string, newPassword: string): Observable<string> {
    return this.http.post<{ message: string }>(`${environment.apiUrl}/account/change-password`,
      {
        old_password: oldPassword,
        new_password: newPassword
      }
    ).pipe(map(r => r.message));
  }

  // Signup routes

  createAccount(email: string, timezone: string): Observable<string> {
    return this.http.post<{ message: string }>(`${environment.apiUrl}/signup/create`,
      {
        email: email,
        timezone: timezone
      }
    ).pipe(map(r => r.message));
  }

  verifyEmail(email: string, confirmationCode: string): Observable<string> {
    return this.http.post<{ message: string }>(`${environment.apiUrl}/signup/verify-email`,
      {
        email: email,
        confirmation_code: confirmationCode
      }
    ).pipe(map(r => r.message));
  }

  setPassword(email: string, confirmationCode: string, password: string): Observable<{ message: string, access_token: string }> {
    return this.http.post<{ message: string, access_token: string }>(`${environment.apiUrl}/signup/set-password`,
      {
        email: email,
        confirmation_code: confirmationCode,
        password: password
      }
    );
  }

  resendVerificationMail(email: string): Observable<string> {
    return this.http.post<{ message: string }>(`${environment.apiUrl}/signup/resend-verification-mail`,
      {
        email: email
      }
    ).pipe(map(r => r.message));
  }

  createLicense(scope: string[], analyticsOptOut: boolean, offline: boolean, customOverlayLogo: boolean): Observable<string> {
    return this.http.post<{ license_id: string }>(`${environment.apiUrl}/license/create`,
      {
        scope: scope,
        analytics_opt_out: analyticsOptOut,
        offline: offline,
        custom_overlay_logo: customOverlayLogo
      }
    ).pipe(map(r => r.license_id));
  }

  deleteLicense(id: string): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/license/${id}`);
  }

  getSubscriptionPayments(): Observable<PaymentInfo[]> {
    return this.http.get<PaymentInfo[]>(`${environment.apiUrl}/account/subscription/payments`);
  }

  getSubscriptionUsage(): Observable<SubscriptionUsage> {
    return this.http.get<SubscriptionUsage>(`${environment.apiUrl}/account/subscription/usage`);
  }

  getPaddleUserInfo(): Observable<PaddleUserInfo> {
    return this.http.get<PaddleUserInfo>(`${environment.apiUrl}/account/subscription/user-info`);
  }

  getScanStats(fromDate: Date, toDate: Date): Observable<ScanStats> {
    return this.http.post<ScanStats>(`${environment.apiUrl}/account/subscription/stats`, {
      from_date: fromDate,
      to_date: toDate
    });
  }

  exportScansToCSV(fromDate: Date, toDate: Date): Observable<ArrayBuffer> {
    return this.http.post(`${environment.apiUrl}/account/subscription/export-to-csv`, {
      from_date: fromDate,
      to_date: toDate
    }, {
      responseType: 'arraybuffer'
    });
  }

  upgradeToProfessional(): Observable<any> {
    return this.http.post(`${environment.apiUrl}/account/subscription/upgrade-to-professional`, {
    });
  }

  getInvoices(): Observable<PaddleInvoice[]> {
    return this.http.get<{ invoices: PaddleInvoice[] }>(
      `${environment.apiUrl}/account/invoices`).pipe(map(r => r.invoices));
  }

  getInvoicePDF(invoiceId: string): Observable<string> {
    return this.http.get<{ url: string }>(
      `${environment.apiUrl}/account/invoices/${invoiceId}/pdf`).pipe(map(r => r.url));
  }
}
