<app-topnav></app-topnav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content">
    <main *ngIf="account">
      <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
        <div class="container-xl px-4">
          <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto mt-4">
                <h1 class="page-header-title">Subscription billing</h1>
                <div class="page-header-subtitle">Your billing history, access to receipts and updating your payment method.</div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="container-xl px-4 mt-n10">

        <div *ngIf="account.isEnterprise" class="row mt-4">
          <div class="col">
            <div class="card">
              <div class="card-header">Enterprise invoice history</div>
              <div class="card-body">

                <div class="progress" *ngIf="fetchingInvoices">
                  <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%"></div>
                </div>

                <p class="card-text" *ngIf="invoices !== undefined && invoices.length === 0">
                  No invoices have been issued yet.
                </p>

                <table class="table" *ngIf="invoices && invoices.length > 0">
                  <thead>
                  <tr>
                    <th scope="col">Issued at</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Due at</th>
                    <th scope="col" class="text-end">PDF</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let inv of invoices">
                    <th scope="row">{{ inv.issuedAt }}</th>
                    <td>{{ inv.totalAmountWithCurrency }}</td>
                    <td>
                      <span class="badge badge-sm" [ngClass]="inv.badgeClass">{{ inv.status }}</span>
                      <ng-container *ngIf="inv.canCheckout">
                        &nbsp;<a href="javascript:void(0)" (click)="payInvoice(inv)">Pay now</a>
                      </ng-container>
                    </td>
                    <td>
                      {{ inv.dueAt }}
                    </td>
                    <td class="text-end">
                      <a href="javascript:void(0)" (click)="getInvoicePDF(inv.id)">Invoice PDF</a>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="account.isPaddle">
          <div class="row mt-4">
            <div class="col">
              <div class="card">
                <div class="card-header">Paddle billing history</div>
                <div class="card-body">

                  <div *ngIf="paymentHistoryErrorMessage" class="alert alert-danger" role="alert">
                    We encountered an error while fetching your payment history. Please try again later, or contact
                    support if the issue persists.
                  </div>

                  <p class="card-text" *ngIf="!fetchingPayments">
                    If you see a payment with a zero amount, do not be alarmed — that's our reseller Paddle checking the
                    validity of your credit card at the start the trial subscription.
                  </p>

                  <div class="progress" *ngIf="fetchingPayments">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%"></div>
                  </div>

                  <table class="table" *ngIf="paymentInfos.length > 0">
                    <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col" class="text-end">Receipt</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let pi of paymentInfos">
                      <th scope="row">{{ pi.payout_date }}</th>
                      <td>{{ pi.amount }} {{ pi.currency }}</td>
                      <td>
                        <span class="badge badge-sm bg-success" *ngIf="pi.is_paid; else upcomingPayment">Paid</span>
                        <ng-template #upcomingPayment>
                          <span class="badge badge-sm bg-primary">Upcoming</span>
                        </ng-template>
                      </td>
                      <td class="text-end">
                        <a *ngIf="pi.receipt_url; else noReceipt" [href]="pi.receipt_url" target="_blank">View receipt</a>
                        <ng-template #noReceipt>
                          <span>-</span>
                        </ng-template>
                      </td>
                    </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!account.isEnterprise" class="row mt-4">
            <div class="col">
              <div class="card">
                <div class="card-header">Update payment method</div>
                <div class="card-body">

                  <p class="card-text">
                    Your billing data is managed by Paddle, our <a href="https://www.paddle.com/about/why-has-paddle-charged-me">Merchant of Record</a>.
                    We do not have access to your billing details, and will never ask you for it.
                    To update your payment method, press the button below, which will open a popup that will
                    lead you through updating your billing data at Paddle.
                  </p>

                  <button class="btn btn-outline-primary" [disabled]="updatingPaymentMethod"
                          (click)="updatePaymentMethod()">
                    Update payment method
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div *ngIf="!account.hasSubscription" class="row mt-4">
          <div class="col">
            <div class="card">
              <div class="card-header">No subscription</div>
              <div class="card-body">
                <p class="card-text">
                  You either do not have a subscription yet, or are on custom or Enterprise subscription, for
                  which do not currently offer access to billing data through the Customer Portal.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div> <!-- container -->
    </main>
    <app-footer></app-footer>
  </div>
</div>
