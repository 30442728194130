import { ValidationErrors } from "@angular/forms";
import * as ipaddr from "ipaddr.js"

export function validateLicenseScope(value: string, wildcardsAllowed: boolean): [boolean, ValidationErrors | string[]] {
  if (!value) {
    return [false, {required: `At least one valid URL is required.`}];
  }

  const entries = value.trim().split(',').map(token => token.trim());
  for (const entry of entries) {
    try {
      if (entry.includes('*')) {
        if (!wildcardsAllowed) {
          return [false, {no_wildcards: `URL ${entry} contains wildcard '*', wildcards are not allowed for this account. Please contact support@strich.io to request this capability.`}];
        }

        const numWildcards = entry.split('*').length - 1;
        if (numWildcards > 1) {
          return [false, {single_wildcard: `URL ${entry} contains more than one wildcard '*', only a single wildcard is allowed.`}];
        }
      }

      const url = new URL(entry);
      if (url.protocol.toLowerCase() !== 'https:') {
        return [false, {insecure_url: `URL ${entry} is not a HTTPS URL (a secure origin is required for camera access).`}];
      }

      if (url.hostname === 'localhost') {
        return [false, {redundant_localhost: `The loopback interface (localhost) is included by default and should not be specified.`}];
      }

      if (entry.includes('*')) {
        const components = url.hostname.split('.');
        if (components[0] != '%2A' || components.length < 3) { // '*' is %2A in the parsed URL (url encoding)
          return [false, {wildcard_first: `The wildcard character needs to be the first component of the hostname (https://*.domain.tld)`}];
        }
      }

      try {
        const ipAddress = ipaddr.parse(url.hostname);
        if (['private', 'loopback'].includes(ipAddress.range())) {
          return [false, {redundant_private_ip: `${entry} is an private or loopback IP address, which is included by default.`}];
        }
      } catch (e) {
        // this is fine
      }
    } catch (e) {
      return [false, {invalid_url: `Entry ${entry} is not a valid URL.`}];
    }
  }
  return [true, entries];
}
