import { Modal } from "bootstrap";

/**
 * Show and return Bootstrap modal using Bootstrap JS api
 */
export function showBootstrapModal(elementId: string, modalBackdrop?: boolean | 'static', dismissWithEscapeKey?: boolean): Modal | null {
  const modalElem = document.getElementById(elementId);
  if (modalElem === null) {
    console.error(`Did not find element id ${elementId} while attempting to present modal`);
    return null;
  }
  // see: https://getbootstrap.com/docs/5.3/components/modal/#options
  const keyboard = dismissWithEscapeKey ?? true;
  const backdrop = modalBackdrop ?? true;
  const modal = new (window as any).bootstrap.Modal(modalElem, {
    backdrop,
    keyboard
  });
  modal.show();
  return modal;
}

/**
 * Hide Bootstrap modal using Bootstrap JS api
 * @param elementId
 */
export function hideBootstrapModal(elementId: string) {
  const modalElem = document.getElementById(elementId)
  if (modalElem === null) {
    console.error(`Did not find element id ${elementId} while attempting to dismiss modal`);
    return;
  }
  (window as any).bootstrap.Modal.getInstance(modalElem)?.hide();
}
