import { Component } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { HttpErrorResponse } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppUrls } from "../../app-urls";
import { finalize } from "rxjs";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  emailFormGroup: FormGroup;
  errorMessage = '';
  busy = false;

  constructor(private api: ApiService,
              private router: Router,
              private formBuilder: FormBuilder) {
    this.emailFormGroup = formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  initiatePasswordReset() {
    this.busy = true;
    this.errorMessage = '';
    this.api.initiatePasswordReset(this.emailFormGroup.value.email).pipe(
      finalize(() => {
        this.busy = false;
      })
    ).subscribe({
      next: message => {
        this.router.navigate([AppUrls.Root, AppUrls.ResetPassword], {
          queryParams: {
            email: this.emailFormGroup.value.email,
            resetCodeSent: true
          }
        });
      },
      error: err => {
        if (err instanceof HttpErrorResponse) {
          if (err.error && 'message' in err.error) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = err.message;
          }
        } else {
          this.errorMessage = err.toString();
        }
      }
    });
  }
}
