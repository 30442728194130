import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
// see: https://www.alexzhao.me/blog/setting-your-canonical-url-in-angular
export class CanonicalService {

  private readonly canonicalLink: HTMLLinkElement;

  constructor(@Inject(DOCUMENT) private document: Document,
              private router: Router) {

    this.canonicalLink = this.document.createElement('link');
    this.canonicalLink.setAttribute('rel', 'canonical');
    this.canonicalLink.setAttribute('href', 'https://portal.strich.io');
    this.document.head.append(this.canonicalLink);

    // update href on navigation end events
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(() => {
      this.canonicalLink.setAttribute('href', this.document.URL);
    });
  }
}
