<app-topnav></app-topnav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content">
    <main>
      <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-2">
        <div class="container-xl px-4">
          <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto mt-4">
                <h1 class="page-header-title">
                  Subscription Usage
                </h1>
                <div class="page-header-subtitle">Gain insights into your user's scanning behavior.</div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="container-xl px-4 mt-4">

        <!-- show a progress bar for the initial load -->
        <div class="row mt-4" *ngIf="loading">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                 style="width: 100%"></div>
          </div>
        </div>

        <!-- error message, hidden when loading -->
        <div *ngIf="errorMessage" class="alert alert-danger mt-4">
          {{ errorMessage }}
        </div>

        <div class="row mt-4">
          <div class="col">
            <div class="card">
              <div class="card-body">

                <div class="container-fluid p-0" *ngIf="viewModel">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="btn-group">
                        <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                          {{ viewModel.rangeName }}
                        </button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" (click)="setRange(DateRange.TODAY)" [routerLink]="">Today</a></li>
                          <li><a class="dropdown-item" (click)="setRange(DateRange.YESTERDAY)" [routerLink]="">Yesterday</a></li>
                          <li><a class="dropdown-item" (click)="setRange(DateRange.LAST_7_DAYS)" [routerLink]="">Last 7 Days</a></li>
                          <li><a class="dropdown-item" (click)="setRange(DateRange.LAST_30_DAYS)" [routerLink]="">Last 30 Days</a></li>
                          <li><hr class="dropdown-divider"></li>
                          <li><a class="dropdown-item" (click)="setRange(DateRange.LAST_MONTH)" [routerLink]="">Last Month</a></li>
                          <li><a class="dropdown-item" (click)="setRange(DateRange.LAST_12_MONTHS)" [routerLink]="">Last 12 Months</a></li>
                          <li><hr class="dropdown-divider"></li>
                          <li><a class="dropdown-item" (click)="setRange(DateRange.MONTH_TO_DATE)" [routerLink]="">Month to Date</a></li>
                          <li><a class="dropdown-item" (click)="setRange(DateRange.YEAR_TO_DATE)" [routerLink]="">Year to Date</a></li>
                        </ul>
                      </div>
                      <button type="button" class="btn btn-outline-blue ms-2" (click)="exportToCSV()">Export to CSV…</button>
                    </div>
                    <div class="col-sm-6 mt-2 text-sm-end">
                      <span class="h1">{{ viewModel.totalScansFmt }}</span> scans by <span class="h2">{{ viewModel.totalDevices }}</span> devices
                    </div>
                  </div>
                </div>

                <div class="chart-container mt-4">
                  <canvas #usageChart class="chart-canvas"></canvas>
                </div>

                <div class="row mt-4">
                  <div class="col">
                    <p class="small mb-0">Not seeing your scans? Incoming scans can delayed by up to one hour or more,
                      depending on browser specifics.</p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="row">

          <div class="col-lg-4 mt-4">
            <div class="card">
              <div class="card-header">Scans by OS</div>
              <div class="card-body">
                <div class="pie-chart-container">
                  <canvas #osChart class="chart-canvas"></canvas>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 mt-4">
            <div class="card">
              <div class="card-header">Scans by browser</div>
              <div class="card-body">
                <div class="pie-chart-container">
                  <canvas #browserChart class="chart-canvas"></canvas>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 mt-4">
            <div class="card">
              <div class="card-header">Scans by symbology</div>
              <div class="card-body">
                <div class="pie-chart-container">
                  <canvas #symbologyChart class="chart-canvas"></canvas>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 mt-4">
            <div class="card">
              <div class="card-header">Scans by country</div>
              <div class="card-body">
                <table class="table table-sm" *ngIf="viewModel">
                  <tbody>
                  <tr *ngFor="let entry of viewModel.countryDistribution">
                    <td>{{ entry[0] }}</td>
                    <td class="text-end">{{ entry[1] }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-lg-4 mt-4">
            <div class="card">
              <div class="card-header">Popular devices</div>
              <div class="card-body">
                <table class="table table-sm" *ngIf="viewModel">
                  <tbody>
                  <tr *ngFor="let entry of viewModel.deviceTypeDistribution">
                    <td>{{ entry[0] }}</td>
                    <td class="text-end">{{ entry[1] }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="col-lg-4 mt-4" [hidden]="range === DateRange.TODAY || range === DateRange.YESTERDAY">
            <div class="card">
              <div class="card-header">Busiest hours</div>
              <div class="card-body">
                <div class="bar-chart-container">
                  <canvas #busyHoursChart class="chart-canvas"></canvas>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 mt-4" [hidden]="range === DateRange.TODAY || range === DateRange.YESTERDAY">
            <div class="card">
              <div class="card-header">Busiest weekdays</div>
              <div class="card-body">
                <div class="bar-chart-container">
                  <canvas #weekdaysChart class="chart-canvas"></canvas>
                </div>
              </div>
            </div>
          </div>

        </div> <!-- row -->
      </div> <!-- container -->

      <div id="csvExportModal" class="modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Export to CSV</h5>
            </div>
            <div class="modal-body">
              <p>
                Export selected date range to CSV:<br>
                <span class="fw-bold">{{ exportRangeText}}</span>
              </p>
              <p>This might take a few seconds to complete, please be patient.</p>
            </div>
            <div class="modal-footer">
              <button type="button" *ngIf="!exporting" class="btn btn-outline-primary" data-bs-dismiss="modal">Cancel</button>
              <button type="submit" class="btn btn-primary" (click)="doExportToCSV()" [disabled]="exporting">
                <span *ngIf="exporting" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                &nbsp;Export Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
    <app-footer></app-footer>
  </div>
</div>
