import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import {
  BookOpen,
  User,
  Play,
  Archive,
  Meh,
  CheckCircle,
  Copy,
  Activity,
  Key,
  Book,
  ExternalLink,
  LogOut,
  Menu,
  ArrowLeft,
  ArrowRight,
  AlertTriangle,
  HelpCircle
} from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
  BookOpen,
  User,
  Play,
  Archive,
  Meh,
  CheckCircle,
  Copy,
  Activity,
  Key,
  Book,
  ExternalLink,
  LogOut,
  Menu,
  ArrowLeft,
  ArrowRight,
  AlertTriangle,
  HelpCircle
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule {
}
