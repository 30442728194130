<app-topnav></app-topnav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content">
    <main>
      <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-2">
        <div class="container-xl px-4">
          <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto mt-4">
                <h1 class="page-header-title">
                  Your Subscription
                </h1>
                <div class="page-header-subtitle">Manage your STRICH SDK subscription and license keys.</div>
              </div>
            </div>
          </div>
        </div>
      </header>


      <div class="container-xl px-4 mt-4">

        <!-- progress bar while loading -->
        <div class="row mt-4" *ngIf="loading">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: 100%"></div>
          </div>
        </div>

        <!-- error message, hidden when loading -->
        <div *ngIf="errorMessage && !loading" class="alert alert-danger">
          {{ errorMessage }}
        </div>

        <!-- everything that follows requires the view model -->
        <ng-container *ngIf="viewModel">

          <!-- custom subscription: just show type and usage -->
          <div class="row" *ngIf="viewModel.account.hasCustomSubscription; else paddleSubscription">
            <div class="col mt-4">
              <div class="card">
                <div class="card-header">
                  Your subscription plan
                </div>
                <div class="card-body">
                  <p class="card-text">
                    You have a custom <strong>{{ viewModel.account.customSubscriptionType}}</strong> subscription,
                    which is governed through a contract and managed outside of the Customer Portal.
                  </p>

                  <!-- Enterprise contract reference, start date, end date -->
                  <div *ngIf="viewModel.account.isEnterprise">
                    <ng-container *ngIf="viewModel.account.ent_contract_reference">
                      <span>Contract reference: <strong>{{ viewModel.account.ent_contract_reference }}</strong></span><br>
                    </ng-container>
                    <ng-container *ngIf="viewModel.account.ent_contract_start_date">
                      <span>Start date: <strong>{{ viewModel.account.ent_contract_start_date }}</strong></span><br>
                    </ng-container>
                    <ng-container *ngIf="viewModel.account.ent_contract_end_date">
                      <span>End date: <strong>{{ viewModel.account.ent_contract_end_date }}</strong></span>
                    </ng-container>
                  </div>

                  <!-- Account capabilities (Enterprise add-ons) -->
                  <ng-container *ngTemplateOutlet="customCapabilities"></ng-container>

                </div>
              </div>
            </div> <!-- end custom subscription -->
            <div class="col mt-4">
              <div class="card">
                <div class="card-header">
                  Usage
                </div>
                <div class="card-body" *ngIf="!viewModel.isEnterprise; else noUsageEnterprise">
                  <p class="card-text">
                    <span class="h1">{{ viewModel.totalScansFormatted }} scans</span> / {{ viewModel.quotaFormatted }}
                    <br>
                    <span *ngIf="viewModel.hasQuota">({{ viewModel.usagePercentage}}% used)</span>
                  </p>
                  <p class="card-text">
                    by {{ viewModel.totalDevicesFormatted }} devices
                    since {{ viewModel.usageFromDate }}
                    <span class="text-muted text-sm">(updated hourly)</span>
                  </p>
                </div>
              </div>
              <ng-template #noUsageEnterprise>
                <div class="card-body">
                  <p class="card-text">
                    You are on an enterprise subscription plan, which is unlimited and where usage is not tracked.
                  </p>
                </div>
              </ng-template>
            </div>
          </div> <!-- row -->

          <!-- Paddle subscriptions -->
          <ng-template #paddleSubscription>
            <div class="row mt-4">
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    Your subscription plan
                    <div class="float-end" *ngIf="viewModel.account.subscriptionState">
                      <span class="badge" [class]="viewModel.account.subscriptionStateBadgeClass">
                        {{ viewModel.account.subscriptionState }}
                      </span>
                    </div>
                  </div>
                  <div class="card-body">

                    <div *ngIf="fromCreateSubscription" class="alert alert-success">
                      <p>
                        Congratulations, you successfully created a subscription for the STRICH SDK. We are
                        grateful for your support. It might take a moment for your account to update.
                      </p>
                      <p class="mb-0">
                        The <a href="https://docs.strich.io/getting-started.html">Getting Started</a> guide is a good
                        resource to get started with.
                      </p>
                    </div>

                    <div *ngIf="fromUpgradeSubscription" class="alert alert-success">
                      Congratulations, you successfully upgraded your subscription. We are grateful for your support.
                      It might take a moment for your account to update.
                    </div>

                    <p *ngIf="viewModel.account.planName">
                      You are currently subscribed to the <strong>{{ viewModel.account.planName}}</strong> plan.
                    </p>
                    <p *ngIf="viewModel.account.subscriptionState">
                      Your subscription is in <strong>{{ viewModel.account.subscriptionState }}</strong> state.
                      <span *ngIf="viewModel.account.hasCancelledSubscription">
                        Your cancelled subscription will expire on {{ viewModel.account.paddle_cancellation_effective_date }}.
                        Your license will still be valid until that date.
                      </span>
                    </p>

                    <!-- Account capabilities (Enterprise add-ons) -->
                    <ng-container *ngTemplateOutlet="customCapabilities"></ng-container>

                  </div>
                </div>
              </div>
            </div> <!-- row -->

            <!-- Paddle subscription infos: Next payment, usage/quota, Payment method -->
            <div class="row" *ngIf="viewModel.paddleUserInfo; let paddleUserInfo">
              <div class="col-md-4 mt-4">
                <div class="card">
                  <div class="card-header">Next bill</div>
                  <div class="card-body">
                    <ng-container *ngIf="paddleUserInfo.next_payment; else noNextPayment">
                      <h1>{{ paddleUserInfo.next_payment.amount }} {{ paddleUserInfo.next_payment.currency }}</h1>
                      <p class="card-text">
                        due on
                        {{ paddleUserInfo.next_payment.date }}
                      </p>
                      <p class="card-text">
                        <a routerLink="/billing">Billing history
                          <i-feather name="arrow-right" style="width: 16px; height: 16px"></i-feather>
                        </a>
                      </p>
                    </ng-container>

                    <ng-template #noNextPayment>
                      <p class="card-text">
                        No next payment planned.
                      </p>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="card">
                  <div class="card-header">Usage</div>
                  <div class="card-body">

                    <p class="card-text">
                      <span class="h1">{{ viewModel.totalScansFormatted }} scans</span> / {{ viewModel.quotaFormatted }}
                      <br>
                      <span *ngIf="viewModel.hasQuota">({{ viewModel.usagePercentage}}% used)</span>
                    </p>
                    <p class="card-text">
                      by {{ viewModel.totalDevicesFormatted }} devices
                      since {{ viewModel.usageFromDate }}
                      <span class="text-muted text-sm">(updated hourly)</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mt-4">
                <div class="card">
                  <div class="card-header">Payment method</div>
                  <div class="card-body" *ngIf="paddleUserInfo.payment_information; else noPaymentInformation">
                    <p class="card-text" *ngIf="paddleUserInfo.payment_information.payment_method === 'card'; else otherPaymentMethod">
                      Credit card:
                      <strong>{{ viewModel.cardType }}</strong>
                      <br>
                      Last 4 digits: {{ paddleUserInfo.payment_information.last_four_digits }}<br>
                      Expiry date: {{ paddleUserInfo.payment_information.expiry_date }}
                    </p>
                    <ng-template #otherPaymentMethod>
                      <p class="card-text">
                        Payment method: {{ paddleUserInfo.payment_information.payment_method }}
                      </p>
                    </ng-template>
                    <button type="button" class="btn btn-outline-primary" (click)="updatePaymentMethod()">
                      <i-feather name="external-link" style="width: 16px; height: 16px" class="me-2"></i-feather>
                      Update payment method
                    </button>
                  </div>
                  <ng-template #noPaymentInformation>
                    <div class="card-body">
                      <p class="card-text">No payment information on file with Paddle.</p>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div> <!-- row -->

            <div class="row mt-4" *ngIf="viewModel.account.isPaddle && !loading">
              <div class="col">
                <p class="text-muted">
                  Your payments are processed by Paddle, our
                  <a href="https://www.paddle.com/about/why-has-paddle-charged-me">Merchant of Record</a>.
                </p>
              </div>
            </div>

            <!-- upgrade Basic -> Professional -->
            <div class="row" *ngIf="!loading">
              <div class="col-md-6 mt-4" *ngIf="viewModel.account.canUpgradeToProfessional">
                <div class="card">
                  <div class="card-header">Upgrade subscription</div>
                  <div class="card-body">
                    <p class="card-text">
                      Need more scans? Upgrade your subscription to STRICH Professional for 100k scans/month.
                    </p>
                    <button class="btn btn-outline-primary" (click)="upgradeToProfessional()">Upgrade subscription
                    </button>
                  </div>
                </div>
              </div>

              <!-- cancel Paddle subscription -->
              <div class="col-md-6 mt-4" *ngIf="viewModel.account.canCancel">
                <div class="card">
                  <div class="card-header">Cancel subscription</div>
                  <div class="card-body">
                    <p class="card-text">
                      Your needs have changed? You can cancel any time, although we will be sorry to see you go.
                    </p>
                    <button (click)="cancelSubscription()" class="btn btn-outline-danger">
                      Cancel subscription
                    </button>
                  </div>
                </div>
              </div>
            </div> <!-- row -->
          </ng-template> <!-- end paddle -->


        </ng-container>

      </div> <!-- container -->
    </main>
    <app-footer></app-footer>
  </div>
</div>

<ng-template #customCapabilities>

  <!-- Account capabilities (Enterprise add-ons) -->
  <div class="mt-3" *ngIf="viewModel && viewModel.account.hasAnyCustomCapability">
    <hr/>
    <p class="card-text">Your account has the following custom capabilities:</p>
    <ul>
      <li *ngIf="viewModel.account.hasOfflineCapability">
        <b>Offline license check</b> — fully offline operation, no HTTP call for license verification
      </li>
      <li *ngIf="viewModel.account.hasAnalyticsOptOutCapability">
        <b>Opt-out of usage tracking</b> — ability to opt-out of usage tracking
      </li>
      <li *ngIf="viewModel.account.hasCustomOverlayLogoCapability">
        <b>Custom overlay logo</b> — ability to override or hide STRICH logo
      </li>
      <li *ngIf="viewModel.account.hasRelaxedScopeCapability">
        <b>Relaxed license scope</b> — ability to deploy on URLs outside of license scope
      </li>
    </ul>
  </div>
</ng-template>

<!-- modal displayed if user clicks "Upgrade subscription" button -->
<div id="upgradeSubscriptionModal" class="modal" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Upgrade subscription</h5>
      </div>
      <div class="modal-body">
        <p>
          Need more scans? Upgrading to STRICH Professional will give you 10x the capacity, as well as access to
          priority support.
        </p>

        <div class="container-fluid mb-4 mt-4">
          <div class="row">
            <div class="col-sm-8">
              <b>STRICH Professional</b><br>
              Everything in Basic<br>
              Up to 100k scans per month<br>
              Priority support<br>
              <br>
              <p>
                <b>249 USD/EUR/CHF per month</b>
              </p>
            </div>
            <div class="col-sm-4">
              <img src="assets/img/strich_200x200.svg" width="120" height="120" alt="STRICH Logo">
            </div>
          </div>
          <div class="row">
            <p class="small">
              Please note that the upgrade will be charged immediately (prorated).
            </p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="doUpgradeToProfessional()">Upgrade now</button>
        <button type="button" class="btn btn-link" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
