import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map, mergeMap } from "rxjs";
import { Account } from "../models/account";
import { ApiService } from "./api.service";
import { JwtHelperService } from "@auth0/angular-jwt";

export enum AuthState {
  SIGNED_OUT,
  SIGNING_IN,
  SIGNED_IN
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static readonly ACCESS_TOKEN_KEY = 's_at';

  state: BehaviorSubject<AuthState>;
  account = new BehaviorSubject<Account | undefined>(undefined);

  constructor(private api: ApiService,
              jwtHelper: JwtHelperService) {

    const storedToken = localStorage.getItem(AuthService.ACCESS_TOKEN_KEY);
    if (storedToken !== null) {
      if (jwtHelper.isTokenExpired(storedToken)) {
        this.setAccessToken(null);
        this.state = new BehaviorSubject<AuthState>(AuthState.SIGNED_OUT);
      } else {
        // reuse this token
        // console.debug(`Found a stored JWT token, attempting to re-authenticate with it...`);
        this.state = new BehaviorSubject<AuthState>(AuthState.SIGNING_IN);
        setTimeout(() => {
          api.getAccountInfo().subscribe({
            next: account => {
              // console.debug(`... successfully re-authenticated as ${account.email}`);
              this.account.next(account);
              this.state.next(AuthState.SIGNED_IN);
            },
            error: err => {
              // ignore, token might be invalidated or really expired
              // console.error(`Re-authentication failed: ${err}`);
              this.state.next(AuthState.SIGNED_OUT);
            }
          });
        });
      }
    } else {
      this.state = new BehaviorSubject<AuthState>(AuthState.SIGNED_OUT);
    }
  }

  setAccessToken(token: string | null) {
    if (token === null) {
      localStorage.removeItem(AuthService.ACCESS_TOKEN_KEY);
    } else {
      localStorage.setItem(AuthService.ACCESS_TOKEN_KEY, token);
    }
  }

  signIn(email: string, password: string): Promise<Account> {
    return firstValueFrom(this.api.authToken(email, password))
      .then(accessToken => {

        // set the access token so subsequent calls will use it
        this.setAccessToken(accessToken);
        return firstValueFrom(this.api.getAccountInfo()).then(account => {
          return {accessToken, account};
        });
      })
      .then(tokenAndAccount => {
        console.log(`Successfully logged in as ${tokenAndAccount.account.email}`);
        this.account.next(tokenAndAccount.account);
        this.state.next(AuthState.SIGNED_IN);

        return tokenAndAccount.account;
      })
      .catch(err => {

        // remove token from session storage if anything goes wrong
        localStorage.removeItem(AuthService.ACCESS_TOKEN_KEY);
        throw err;
      });
  }

  refreshAccount(): Promise<Account> {
    return new Promise<Account>((resolve, reject) => {
      this.api.getAccountInfo().subscribe({
        next: account => {
          this.account.next(account);
          this.state.next(AuthState.SIGNED_IN);
          resolve(account);
        },
        error: err => {

          // try again
          this.api.getAccountInfo().subscribe({
            next: account => {
              this.account.next(account);
              this.state.next(AuthState.SIGNED_IN);
              resolve(account);
            },
            error: errorOnRetry => {
              this.state.next(AuthState.SIGNED_OUT);
              reject(err);
            }
          });
        }
      });
    });
  }

  isSignedIn(): boolean {
    return this.state.value === AuthState.SIGNED_IN;
  }

  isSignedOut(): boolean {
    return this.state.value === AuthState.SIGNED_OUT;
  }

  signOut(): void {
    this.setAccessToken(null);
    this.state.next(AuthState.SIGNED_OUT);
    this.account.next(undefined);
  }
}
