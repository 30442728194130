import { PaddleUserInfo } from "../models/paddle-user-info";
import { Account } from "../models/account";
import { SubscriptionUsage } from "../models/subscription-usage";
import { NumberFormatUtil } from "../utils/number-format-util";

export class SubscriptionViewModel {

  cardType = '';
  isEnterprise = false;
  hasQuota = false;
  quotaFormatted = '';
  totalScansFormatted = '';
  totalDevicesFormatted = '';
  usageFromDate = '';
  usagePercentage = '';

  constructor(public account: Account,
              public paddleUserInfo?: PaddleUserInfo,
              public subscriptionUsage?: SubscriptionUsage) {
    if (paddleUserInfo) {
      this.cardType = this.formatCardType(paddleUserInfo.payment_information?.card_type);
    }

    this.isEnterprise = account.isEnterprise;
    if (subscriptionUsage) {
      this.totalScansFormatted = NumberFormatUtil.formatScanCount(subscriptionUsage.total_scans);
      this.totalDevicesFormatted = subscriptionUsage.total_devices.toString()
      this.hasQuota = !!subscriptionUsage.quota;
      this.quotaFormatted = this.hasQuota ? NumberFormatUtil.formatScanCount(subscriptionUsage.quota) : '∞';
      this.usageFromDate = new Date(Date.parse(subscriptionUsage.since_date)).toLocaleDateString();
      this.usagePercentage = (subscriptionUsage.total_scans > 0 && this.hasQuota) ?
        ((subscriptionUsage.total_scans / subscriptionUsage.quota) * 100).toFixed(0) : '0';
    }
  }

  private formatCardType(cardType?: string): string {
    if (!cardType) {
      return '';
    }

    switch (cardType) {
      case 'master':
        return 'MasterCard';
      case 'visa':
        return 'Visa';
      case 'american_express':
        return 'AmEx'
      case 'diners_club':
        return 'Diners Club';
      case 'discover':
        return 'Discover';
      case 'jcb':
        return 'JCB';
      case 'mada':
        return 'Mada';
      case 'maestro':
        return 'Maestro';
      case 'union_pay':
        return 'UnionPay';
      case 'unknown':
        return 'Other'
      default:
        return cardType;
    }
  }
}
