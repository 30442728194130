import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from "rxjs";
import { Account } from "../../models/account";
import { AuthService } from "../../services/auth.service";
import { ActivatedRoute, Event, NavigationStart, Router } from "@angular/router";
import { SidenavService } from "../../services/sidenav.service";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  $account: Observable<Account | undefined>;

  private routerEventSubscription?: Subscription;

  constructor(private auth: AuthService,
              private activatedRoute: ActivatedRoute,
              private sidenav: SidenavService,
              private router: Router,
              private elementRef: ElementRef) {
    this.$account = auth.account;

  }

  ngOnInit() {
    this.routerEventSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.sidenav.hide();
      }
    });

    // collapse parent nodes of active navigation link
    const targetAnchors = this.elementRef.nativeElement.querySelectorAll(`[routerLink="${this.router.url}"].nav-link`) as HTMLAnchorElement[];
    targetAnchors.forEach(targetAnchor => {
      let parentNode = targetAnchor.parentNode;
      while (parentNode !== null && parentNode !== document.documentElement && parentNode instanceof HTMLElement) {
        if (parentNode.classList.contains('collapse')) {
          parentNode.classList.add('show');
          const parentNavLink = document.body.querySelector(
            '[data-bs-target="#' + parentNode.id + '"]'
          );
          if (parentNavLink) {
            parentNavLink.classList.remove('collapsed');
            parentNavLink.classList.add('active');
          }
        }
        parentNode = parentNode.parentNode;
      }
      targetAnchor.classList.add('active');
    });
  }

  ngOnDestroy() {
    if (this.routerEventSubscription) {
      this.routerEventSubscription.unsubscribe();
      this.routerEventSubscription = undefined;
    }
  }
}
