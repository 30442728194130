import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { finalize, Subscription } from "rxjs";

import { ApiService } from "../../services/api.service";
import { AppUrls } from "../../app-urls";
import { AuthService } from "../../services/auth.service";
import { Account } from "../../models/account";
import { validateLicenseScope } from "../../utils/license-scope-validator";

@Component({
  selector: 'app-create-license-key',
  templateUrl: './create-license-key.component.html',
  styleUrls: ['./create-license-key.component.scss']
})
export class CreateLicenseKeyComponent implements OnInit, OnDestroy {

  busy: boolean;
  errorMessage?: string;
  formGroup?: FormGroup;
  account?: Account;
  validatedUrls: string[] = [];

  private subscription?: Subscription;

  constructor(private formBuilder: FormBuilder,
              private auth: AuthService,
              private router: Router,
              private api: ApiService) {
    this.busy = false;
  }

  ngOnInit() {
    this.subscription = this.auth.account.subscribe((account) => {
      this.account = account;
      if (account) {
        this.formGroup = this.formBuilder.group({
          'scope': ['', [this.scopeValidator()]],
          'analyticsOptOut': [false],
          'offline': [false],
          'customOverlayLogo': [false]
        });

        // based on license capabilities, disable checkboxes or set check them
        if (!account.hasOfflineCapability) {
          this.formGroup.controls['offline'].disable();
        } else {
          this.formGroup.patchValue({ offline: true });
        }

        if (!account.hasAnalyticsOptOutCapability) {
          this.formGroup.controls['analyticsOptOut'].disable();
        } else {
          this.formGroup.patchValue({ analyticsOptOut: true });
        }

        if (!account.hasCustomOverlayLogoCapability) {
          this.formGroup.controls['customOverlayLogo'].disable();
        } else {
          this.formGroup.patchValue({ customOverlayLogo: true });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  get scope() {
    return this.formGroup?.get('scope');
  }

  createLicenseKey() {
    if (!this.formGroup?.valid) {
      return;
    }

    this.busy = true;
    const scope = (this.formGroup.value.scope as string).trim().split(',').map(entry => entry.trim());

    // values are 'undefined' if their form controls are disabled
    const analyticsOptOut = this.formGroup.value.analyticsOptOut !== undefined ?
      (this.formGroup.value.analyticsOptOut as boolean) : false;
    const offline = this.formGroup.value.offline !== undefined ?
      (this.formGroup.value.offline as boolean) : false;
    const customOverlayLogo = this.formGroup.value.customOverlayLogo !== undefined ?
      (this.formGroup.value.customOverlayLogo as boolean) : false;
    this.api.createLicense(scope, analyticsOptOut, offline, customOverlayLogo).pipe(
      finalize(() => {
        this.busy = false;
      })
    ).subscribe({
      next: () => {
        this.router.navigate([AppUrls.Root, AppUrls.LicenseKeys], {
          queryParams: {
            fromCreate: true
          }
        });
      },
      error: err => {
        this.errorMessage = this.getErrorMessage(err);
      }
    });
  }

  private scopeValidator(): ValidatorFn {
    const wildcardsAllowed = this.account?._hasWildcardScopeCapability ?? false;
    return (control: AbstractControl): ValidationErrors | null => {
      const result = validateLicenseScope(control.value, wildcardsAllowed);
      if (!result[0]) {
        this.validatedUrls = [];
        return result[1];
      } else {
        this.validatedUrls = result[1] as string[];
        return null; // 'ok'
      }
    };
  }

  private getErrorMessage(err: any): string {
    if (err instanceof HttpErrorResponse) {
      if (err.error && 'message' in err.error) {
        return err.error.message;
      } else {
        return err.message;
      }
    } else {
      return err.toString();
    }
  }
}
