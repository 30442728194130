/**
 * Return a shortened version of the email address for display purposes.
 *
 * E.g. a.rather.long.annoying@email.company.com --> a.ra...@..company.com
 */
export function shortenEmail(email: string): string {
  const parts =  email.split('@');
  if (parts.length != 2) {
    return email; // this is unexpected, bail out
  }
  const firstPart = parts[0].length > 10 ? parts[0].substring(0, 10) + '…' : parts[0];
  const secondPart = parts[1].length > 14 ? parts[1].substring(0, 14) + '…' : parts[1];
  return firstPart + '@' + secondPart;
}
