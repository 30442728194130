import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";
import { AuthService } from "../../services/auth.service";
import { finalize, Subscription } from "rxjs";
import { Account } from "../../models/account";
import { ApiService } from "../../services/api.service";
import { PaymentInfo } from "../../models/payment-info";
import { PaddleService } from "../../services/paddle.service";
import { PaddleInvoice } from "../../models/paddle-invoice";
import { InvoiceViewModel } from "../../view-models/invoice.viewmodel";

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit, OnDestroy {

  paymentHistoryErrorMessage?: string;
  fetchingPayments: boolean;
  paymentInfos: PaymentInfo[];

  fetchingInvoices: boolean;
  invoices?: InvoiceViewModel[];
  account?: Account;
  updatingPaymentMethod: boolean;

  private subscription?: Subscription;

  constructor(private auth: AuthService,
              private paddle: PaddleService,
              private api: ApiService) {
    this.fetchingPayments = true;
    this.fetchingInvoices = false;
    this.updatingPaymentMethod = false;
    this.paymentInfos = [];
  }

  ngOnInit() {
    this.subscription = this.auth.account.subscribe({
      next: account => {
        this.account = account;

        if (this.account?.isEnterprise) {
          this.fetchingInvoices = true;
          this.api.getInvoices().pipe(
            finalize(() => {
              this.fetchingInvoices = false;
            })
          ).subscribe(invoices => {
            this.invoices = invoices.map(inv => new InvoiceViewModel(inv));
          });
        }
      }
    });

    this.fetchingPayments = true;
    this.api.getSubscriptionPayments().pipe(
      finalize(() => {
        this.fetchingPayments = false;
      })
    ).subscribe({
      next: paymentInfos => {
        this.paymentInfos = paymentInfos;
      },
      error: err => {
        this.paymentHistoryErrorMessage = err.toString();
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  updatePaymentMethod() {
    if (!this.account) {
      return;
    }

    this.updatingPaymentMethod = true;
    this.paddle.updatePaymentMethod(this.account).finally(() => {
      return this.refreshAccount().finally(() => {
        this.updatingPaymentMethod = false;
      });
    })
  }

  payInvoice(invoice: InvoiceViewModel) {
    if (!invoice.checkoutUrl) {
      return;
    }

    this.paddle.payInvoice(invoice.checkoutUrl);
  }

  getInvoicePDF(invoiceId: string) {
    this.api.getInvoicePDF(invoiceId).subscribe({
      next: pdfUrl => {
        const win = window.open(pdfUrl, '_blank');
        if (!win) {
          console.error(`Failed to open window for URL: ${pdfUrl}`);
        }
      },
      error: err => {
        console.error(`Failed to fetch invoice PDF: ${err.message}`);
        // TODO: report error to user
      }
    });
  }

  private refreshAccount(): Promise<Account | void> {
    return this.auth.refreshAccount().catch(err => {
      console.error(`Failed to refresh account: ${err}`);
      // TODO: retry, error display
    });
  }
}
