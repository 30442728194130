<nav *ngIf="$account | async as account" class="sidenav shadow-right sidenav-light">
  <div class="sidenav-menu">
    <div class="nav accordion" id="accordionSidenav">

      <!-- Sidenav Heading (Overview)-->
      <div class="sidenav-menu-heading">Overview</div>
      <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
        <div class="nav-link-icon">
          <i-feather name="activity"></i-feather>
        </div>
        Dashboard
      </a>

      <div class="sidenav-menu-heading">STRICH SDK</div>

      <a class="nav-link collapsed" [routerLink]="" routerLinkActive="active" data-bs-toggle="collapse" data-bs-target="#collapseSubscription">
        <div class="nav-link-icon">
          <i-feather name="key" class="sidenav-feather"></i-feather>
        </div>
        Subscription
        <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
      </a>
      <div class="collapse" id="collapseSubscription" data-bs-parent="#accordionSidenav">
        <nav class="sidenav-menu-nested nav">
          <a *ngIf="account.hasSubscription" class="nav-link" routerLink="/subscription" routerLinkActive="active">Overview</a>
          <a *ngIf="account.hasSubscription" class="nav-link" routerLink="/usage" routerLinkActive="active">Usage</a>
          <a *ngIf="account.hasSubscription" class="nav-link" routerLink="/license-keys" routerLinkActive="active">License Keys</a>
          <a *ngIf="account.hasSubscription" class="nav-link" routerLink="/billing" routerLinkActive="active">Billing</a>
          <a *ngIf="!account.hasSubscription" class="nav-link" routerLink="/create-subscription" routerLinkActive="active">Sign Up</a>
        </nav>
      </div>

      <a class="nav-link collapsed" [routerLink]="" data-bs-toggle="collapse" data-bs-target="#collapseDocumentation">
        <div class="nav-link-icon"><i-feather name="book"></i-feather></div>
        Documentation
        <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
      </a>
      <div class="collapse" id="collapseDocumentation" data-bs-parent="#accordionSidenav">
        <nav class="sidenav-menu-nested nav">
          <a class="nav-link" href="https://docs.strich.io/getting-started.html?ref=portal" target="_blank">
            <div class="nav-link-icon">
              <i-feather name="external-link"></i-feather>
            </div>
            Getting Started
          </a>
          <a class="nav-link" href="https://docs.strich.io/reference/index.html?ref=portal" target="_blank">
            <div class="nav-link-icon">
              <i-feather name="external-link"></i-feather>
            </div>
            API Reference
          </a>
          <a class="nav-link" href="https://strich.io/faq.html" target="_blank">
            <div class="nav-link-icon">
              <i-feather name="external-link"></i-feather>
            </div>
            FAQ
          </a>
        </nav>
      </div>

      <div class="sidenav-menu-heading">Support</div>
      <a class="nav-link" routerLink="/support" routerLinkActive="active">
        <div class="nav-link-icon">
          <i-feather name="help-circle"></i-feather>
        </div>
        Support
      </a>

      <div class="sidenav-menu-heading">Settings</div>

      <a class="nav-link collapsed" [routerLink]="" data-bs-toggle="collapse" data-bs-target="#collapseAccount">
        <div class="nav-link-icon"><i-feather name="user"></i-feather></div>
        Account
        <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
      </a>
      <div class="collapse" id="collapseAccount" data-bs-parent="#accordionSidenav">
        <nav class="sidenav-menu-nested nav">
          <a class="nav-link" routerLink="/account/security" routerLinkActive="active">Security</a>
          <a class="nav-link" routerLink="/account/notifications" routerLinkActive="active">Notifications</a>
        </nav>
      </div>
    </div>
  </div>
  <div *ngIf="account" class="sidenav-footer">
    <div class="sidenav-footer-content">
      <div class="sidenav-footer-subtitle">Logged in as:</div>
      <div class="sidenav-footer-title">{{ account.shortenedEmail }}</div>
    </div>
  </div>
</nav>
