<div id="layoutAuthentication">
  <div id="layoutAuthentication_content">
    <main>
      <div class="container-xl px-4 mt-10">
        <div class="row justify-content-center">

          <div class="col-lg-5">

            <a href="https://strich.io">
              <img src="assets/img/strich-logo-navbar.svg" width="139" height="30" alt="STRICH Logo">
            </a>

            <!-- Basic forgot password form-->
            <div class="card shadow-lg border-0 rounded-lg mt-4">
              <div class="card-header justify-content-center">
                <h1 class="fw-light my-2">Password Reset</h1>
              </div>
              <div class="card-body">

                <div *ngIf="resetCodeSent" class="alert alert-success">
                  We sent a reset code to your email address. Enter it in the form below to set a new passsword.
                </div>

                <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                  {{ errorMessage }}
                </div>

                <p class="card-text text-sm mb-3">
                  <span class="text-sm text-muted">
                    It can take a few minutes for the email to arrive. Check your spam folder if it does not arrive
                    after 5 minutes.
                    </span>
                </p>

                <!-- Forgot password form-->
                <form [formGroup]="formGroup" (ngSubmit)="resetPassword()">
                  <!-- Form Group (email address)-->
                  <div class="mb-3">
                    <label class="small mb-1" for="email">Email</label>
                    <input class="form-control" id="email" type="email" aria-describedby="emailHelp"
                           placeholder="user@example.com" autocomplete="email" formControlName="email"/>
                  </div>

                  <!-- Form Group (reset code)-->
                  <div class="mb-3">
                    <label class="small mb-1" for="resetCode">Reset code</label>
                    <input class="form-control" id="resetCode" type="text"
                           placeholder="Reset code from email"
                           formControlName="resetCode"
                    />
                  </div>

                  <!-- Form Group (new password)-->
                  <div class="mb-3">
                    <label class="small mb-1" for="newPassword">New Password</label>
                    <input class="form-control" id="newPassword" type="password"
                           placeholder="Enter new password"
                           formControlName="newPassword"
                    />
                  </div>
                  <!-- Form Group (new password confirmation)-->
                  <div class="mb-3">
                    <label class="small mb-1" for="newPasswordConfirmation">New Password (confirmation)</label>
                    <input class="form-control" id="newPasswordConfirmation" type="password" placeholder="Confirm new password"
                           formControlName="newPasswordConfirmation"/>
                  </div>

                  <!-- Form Group (submit options)-->
                  <div class="d-flex align-items-center justify-content-between mt-4 mb-0">
                    <a class="small" routerLink="/login">Return to login</a>
                    <button class="btn btn-primary" type="submit" [disabled]="!formGroup.valid">Reset Password</button>
                  </div>
                </form>
              </div>

              <div class="card-footer text-center">
                <div class="small"><a routerLink="/register">Need an account? Sign up!</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div id="layoutAuthentication_footer">
    <app-footer></app-footer>
  </div>
</div>
