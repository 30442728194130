<div id="layoutLegal" xmlns="http://www.w3.org/1999/html">
  <div id="layoutLegal_content">
    <main>
      <div class="container-md px-4 mt-10">
        <div class="row justify-content-center">
          <div class="col">

            <a routerLink="/dashboard">
              <img src="assets/img/strich-logo-navbar.svg" width="139" height="30" alt="STRICH Logo">
            </a>

            <header style="margin-top: 2rem">

              <h2>Privacy Policy</h2>

              <div class="alert alert-info mt-4" role="alert">
                <strong>TL,DR; version:</strong> We care about your privacy. We ask only for the bare minimum of data
                required to do business with you. If you decide to create an account with us, we collect your email
                address for authentication purposes (so you can log in to this site). We don't want your personal
                data, and storing sensitive data is something we don't like to do.
                We use Plausible Analytics, a privacy-friendly Analytics solution that does not require cookies, to
                better understand how users navigate our site. Check their
                <a href="https://plausible.io/data-policy">data policy</a> for details.
                For anything billing-related, we use <a href="https://www.paddle.com/">Paddle</a> as a
                <a href="https://www.paddle.com/about/why-has-paddle-charged-me">Merchant of Record</a>, so we don't
                have to deal with the nitty-gritty payment stuff and can focus on our product instead.
                Check Paddle's <a href="https://www.paddle.com/legal/privacy">privacy policy</a> for details.
              </div>
            </header>

            <section>
              <p>
                Our guiding principle is to collect only what we need and that we will solely process this information
                to provide you with the service you signed up for.
                <br>
                We use a select number of trusted external service providers for certain service offerings. These
                service providers are carefully selected and meet high data protection, data privacy and security standards.
              </p>
            </section>
            <section>
              <h3>What We Collect and Why</h3>

              <p>
                <b>An email address and password are required to create an account.</b>
                You need to provide us with your email address if you want to use the STRICH SDK. That’s just so you
                can log in and create a subscription, manage your license keys receive critical information from us.
                All our infrastructure runs on Amazon Web Services (AWS). Emails are sent through the SES
                (Simple Email Service) service, an industry-standard service.
                Please refer to the <a href="https://docs.aws.amazon.com/ses/latest/dg/data-protection.html">SES Data Protection</a> documentation
                for details.
                We do not track email open or link click events, and currently only send transactional email.
              </p>

              <p>
                <b>The payment process is handled by Paddle, our Merchant of Record.</b>
                When you start a subscription for using the STRICH SDK, the billing information and the payment process
                is handled by Paddle. See the <a href="https://paddle.com/privacy-buyers/">Paddle Privacy Policy</a> for full details.
              </p>

              <p>
                <b>We do NOT use cookies.</b>
                This website uses Plausible Analytics to help us understand how visitors engage with our website,
                and to measure the effectiveness of our marketing campaigns. We chose Plausible Analytics because it is
                a privacy-focused company and platform that eschews personally identifiable information in favor of
                anonymous aggregate data. See the Plausible Analytics <a href="https://plausible.io/data-policy">Data Policy</a>
                for details. Plausible does not use cookies.
              </p>

              <p>
                <b>IP address and User Agent</b>
                When you visit and interact with the Customer Portal, due to how the internet and the HTTP protocol
                work, we receive your IP address along with some basic information about your browser. To be able to
                identify issues in the Customer Portal, this information may be logged. Log data is retained in AWS
                CloudWatch for <b>3 months</b>.
              </p>
            </section>

            <section>
              <h3>Changes and questions</h3>

              <p>We may update this policy as needed to comply with relevant regulations and reflect any new practices.
              Whenever we make a significant change to our policies, we will also announce them on our company blog or
              social media profiles.</p>

              <p>Contact us at <a href="mailto:hello@pixelverse.ch">hello@pixelverse.ch</a> if you have any questions,
                comments, or concerns about this privacy policy, your data, or your rights with respect to your information.
              </p>

              <p>Last updated: January 5, 2024</p>
            </section>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div id="layoutLegal_footer">
    <app-footer></app-footer>
  </div>
</div>
