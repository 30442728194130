import { NumberFormatUtil } from "../utils/number-format-util";
import { DateTime } from "luxon";
import { ScanStats } from "../models/scan-stats";
import { getFlagEmoji } from "../utils/i18n-util";

export enum UsageDateRange {
  TODAY,
  YESTERDAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  LAST_MONTH,
  MONTH_TO_DATE,
  YEAR_TO_DATE,
  LAST_12_MONTHS
}

type DateKey = string;

/**
 * Return a tuple of [start date, end date] for the given range.
 * @param dateRange
 */
export function datesFromRange(dateRange: UsageDateRange): [DateTime, DateTime] {
  let endDate = DateTime.now().endOf('day');
  switch (dateRange) {
    case UsageDateRange.TODAY:
      return [endDate.startOf('day'), endDate];
    case UsageDateRange.YESTERDAY:
      const yesterday = DateTime.now().minus({ day: 1 }).startOf('day');
      return [yesterday, yesterday.endOf('day')];
    case UsageDateRange.LAST_7_DAYS:
      return [endDate.minus({ day: 7 }).startOf('day'), endDate];
    case UsageDateRange.LAST_30_DAYS:
      return [endDate.minus({ day: 30 }).startOf('day'), endDate];
    case UsageDateRange.LAST_MONTH:
      endDate = DateTime.now().minus({ month: 1}).endOf('month').endOf('day');
      return [endDate.startOf('month'), endDate];
    case UsageDateRange.MONTH_TO_DATE:
      return [endDate.startOf('month'), endDate];
    case UsageDateRange.YEAR_TO_DATE:
      return [endDate.startOf('year'), endDate];
    case UsageDateRange.LAST_12_MONTHS:
      endDate = DateTime.now().minus({ month: 1}).endOf('month').endOf('day');
      return [endDate.minus({ month: 12 }).startOf('day'), endDate];
  }
}

export function nameForDateRange(dateRange: UsageDateRange): string {
  switch (dateRange) {
    case UsageDateRange.TODAY:
      return 'Today';
    case UsageDateRange.YESTERDAY:
      return 'Yesterday';
    case UsageDateRange.LAST_7_DAYS:
      return 'Last 7 days';
    case UsageDateRange.LAST_30_DAYS:
      return 'Last 30 days';
    case UsageDateRange.LAST_MONTH:
      return 'Last month';
    case UsageDateRange.MONTH_TO_DATE:
      return 'Month to date (MTD)';
    case UsageDateRange.YEAR_TO_DATE:
      return 'Year to date (YTD)'
    case UsageDateRange.LAST_12_MONTHS:
      return 'Last 12 months';
  }
}

export class UsageViewModel {

  // e.g. 'Last 30 days'
  rangeName: string;

  // @ts-ignore
  startDateFmt: string;

  // @ts-ignore
  endDateFmt: string;

  // usage charts
  chartDataSetLabels: string[];
  chartDataSetValues: number[];

  totalScansFmt: string;
  totalScans: number;
  totalDevices: number;

  // OS distribution, e.g.: [('iOS', 100234), ('Android', 234561), ('N/A', 415132)]
  osChartDataSetLabels: string[];
  osChartDataSetValues: number[];

  // ditto for browsers
  browserChartDataSetLabels: string[];
  browserChartDataSetValues: number[];

  // ditto for devices
  deviceTypeDistribution: [string, string][];

  // e.g. ['9:00-10:00', ...]
  busyHoursDataSetLabels: string[];
  busyHoursDataSetValues: number[];

  // e.g. ['Monday', 'Tuesday', ... ]
  weekdaysDataSetLabels: string[];
  weekdaysDataSetValues: number[];

  symbologyDataSetLabels: string[];
  symbologyDataSetValues: number[];

  // by country
  countryDistribution: [string, string][];

  constructor(dateRange: UsageDateRange, scanStats: ScanStats) {

    this.rangeName = nameForDateRange(dateRange);

    const scanCountByDate = new Map<DateKey, number>(Object.entries(scanStats.num_scans_per_day));
    const scanCountByHourOfDay = scanStats.num_scans_by_hour_of_day;
    const scanCountByWeekday = scanStats.num_scans_by_day_of_week;

    // calculate OS and browser stats
    let scanCountByOs = new Map<string, number>(Object.entries(scanStats.num_scans_by_os));
    let scanCountByBrowser = new Map<string, number>(Object.entries(scanStats.num_scans_by_browser));
    let scanCountBySymbology = new Map<string, number>(Object.entries(scanStats.num_scans_by_symbology));
    let scanCountByCountry = new Map<string, number>(Object.entries(scanStats.num_scans_by_country_code));
    let scanCountByDeviceType = new Map<string, number>(Object.entries(scanStats.num_scans_by_device_type));

    this.totalScans = scanStats.num_scans;
    this.totalScansFmt = NumberFormatUtil.formatScanCount(this.totalScans);
    this.totalDevices = scanStats.num_devices;

    const symbologyDistribution = this.toOrderedAbsoluteValues(scanCountBySymbology);
    this.symbologyDataSetLabels = symbologyDistribution.map(v => v[0]); // Symbology, e.g. 'code128'
    this.symbologyDataSetValues = symbologyDistribution.map(v => v[1]); // absolute value

    const enRegionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    this.countryDistribution = this.toOrderedPercentages(scanCountByCountry).map(v => {
      if (v[0] !== 'N/A') {
        const label = `${getFlagEmoji(v[0])} ${enRegionNames.of(v[0])}`;
        return [label, v[1]];
      } else {
        return ['🏳 (unknown)', v[1]];
      }
    });

    const osDistribution = this.toOrderedAbsoluteValues(scanCountByOs);
    this.osChartDataSetLabels = osDistribution.map(v => v[0]); // OS labels, e.g. 'Android'
    this.osChartDataSetValues = osDistribution.map(v => v[1]); // absolute value

    const browserDistribution = this.toOrderedAbsoluteValues(scanCountByBrowser);
    this.browserChartDataSetLabels = browserDistribution.map(v => v[0]); // browser names, e.g. 'Chrome'
    this.browserChartDataSetValues = browserDistribution.map(v => v[1]); // absolute scan count

    this.deviceTypeDistribution = this.toOrderedPercentages(scanCountByDeviceType);

    const busyHoursDistribution = this.createBusyHoursDistribution(scanCountByHourOfDay);
    this.busyHoursDataSetLabels = busyHoursDistribution.map(v => v[0]);
    this.busyHoursDataSetValues = busyHoursDistribution.map(v => Math.round((v[1] * 100) * 10) / 10); // round to 1 decimal

    const weekdaysDistribution = this.createWeekdaysDistribution(scanCountByWeekday);
    this.weekdaysDataSetLabels = weekdaysDistribution.map(v => v[0]);
    this.weekdaysDataSetValues = weekdaysDistribution.map(v => Math.round((v[1] * 100) * 10) / 10); // round to 1 decimal

    this.chartDataSetLabels = []; // make TS happy
    this.chartDataSetValues = []; // make TS happy

    if (dateRange === UsageDateRange.TODAY || dateRange === UsageDateRange.YESTERDAY) {
      this.chartDataSetLabels = this.busyHoursDataSetLabels;
      this.chartDataSetValues = scanCountByHourOfDay;
    } else {
      this.createChartDataSet(dateRange, scanCountByDate);
    }
  }

  /**
   * Assemble data set for Chart.js
   * @param dateRange
   * @param scansByDate
   * @private
   */
  private createChartDataSet(dateRange: UsageDateRange, scansByDate: Map<DateKey, number>) {

    // prepare slots (there maybe be no scan events for a given day in the range, so we can't just derive the slots from the events
    let [startDate, endDate] = datesFromRange(dateRange);
    this.startDateFmt = startDate.toISODate();
    this.endDateFmt = endDate.toISODate();

    this.chartDataSetLabels = [];
    this.chartDataSetValues = [];

    // create one data point per day
    let date = startDate;
    while (date < endDate) {
      const dateKey = date.toISODate();
      this.chartDataSetLabels.push(dateKey);
      this.chartDataSetValues.push(scansByDate.get(dateKey) ?? 0);
      date = date.plus({day: 1});
    }
  }

  private toOrderedPercentages(scanEventsByString: Map<string, number>): [string, string][] {
    const distribution: [string, number][] = this.toOrderedPercentageValues(scanEventsByString);
    return distribution.map(t => [t[0], NumberFormatUtil.formatPercentage(t[1])]);
  }

  /**
   * Return an ordered list of (key, percentage) pairs from a map containing (key, absolute count)
   * @private
   */
  private toOrderedPercentageValues(scanEventsByString: Map<string, number>): [string, number][] {
    const distribution: [string, number][] = [];
    for (const entry of scanEventsByString.entries()) {
      const name = entry[0];
      const scanCount = entry[1];
      const pct = this.totalScans === 0 ? 0 : scanCount/this.totalScans;
      distribution.push([name, pct]);
    }

    // sort descending by percentage
    distribution.sort((a, b) => b[1] - a[1]);
    return distribution;
  }

  private toOrderedAbsoluteValues(scanEventsByString: Map<string, number>): [string, number][] {
    const distribution = Array.from(scanEventsByString);
    distribution.sort((a, b) => b[1] - a[1]);
    return distribution;
  }

  private createBusyHoursDistribution(scanEventsByHourOfDay: Array<number>): [string, number][] {
    const totalScans = scanEventsByHourOfDay.reduce((sum, current) => sum + current, 0);
    const distribution: [string, number][] = [
      ['00:00 - 01:00', 0],
      ['01:00 - 02:00', 0],
      ['02:00 - 03:00', 0],
      ['03:00 - 04:00', 0],
      ['04:00 - 05:00', 0],
      ['05:00 - 06:00', 0],
      ['06:00 - 07:00', 0],
      ['07:00 - 08:00', 0],
      ['08:00 - 09:00', 0],
      ['09:00 - 10:00', 0],
      ['10:00 - 11:00', 0],
      ['11:00 - 12:00', 0],
      ['12:00 - 13:00', 0],
      ['13:00 - 14:00', 0],
      ['14:00 - 15:00', 0],
      ['15:00 - 16:00', 0],
      ['16:00 - 17:00', 0],
      ['17:00 - 18:00', 0],
      ['18:00 - 19:00', 0],
      ['19:00 - 20:00', 0],
      ['20:00 - 21:00', 0],
      ['21:00 - 22:00', 0],
      ['22:00 - 23:00', 0],
      ['23:00 - 00:00', 0]
    ];
    for (let i = 0; i < scanEventsByHourOfDay.length; i++) {
      distribution[i][1] = scanEventsByHourOfDay[i]/totalScans;
    }
    return distribution;
  }

  private createWeekdaysDistribution(scanEventsByWeekday: Array<number>): [string, number][] {
    const totalScans = scanEventsByWeekday.reduce((sum, current) => sum + current, 0);
    const distribution: [string, number][] = [
      ['Mon', 0],
      ['Tue', 0],
      ['Wed', 0],
      ['Thu', 0],
      ['Fri', 0],
      ['Sat', 0],
      ['Sun', 0]
    ];
    for (let i = 0; i < scanEventsByWeekday.length; i++) {
      distribution[i][1] = scanEventsByWeekday[i]/totalScans;
    }
    return distribution;
  }
}
