export enum AppUrls {
  Root = '/',

  // unauthenticated routes
  Login = 'login',
  ForgotPassword = 'forgot-password',
  ResetPassword = 'reset-password',
  Register = 'register',
  PrivacyPolicy = 'privacy-policy',

  // authenticated routes
  Dashboard = 'dashboard',
  Subscription = 'subscription',
  CreateSubscription = 'create-subscription',
  LicenseKeys = 'license-keys',
  CreateLicenseKey = 'create-license-key',
  Billing = 'billing',
  Security = 'account/security',
  Notifications = 'account/notifications',
  PurchaseCompleted = 'purchase-completed',
  Support = 'support',
  Usage = 'usage'
}
