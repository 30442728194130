import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {


  constructor() {
  }

  hide() {
    document.body.classList.remove('sidenav-toggled');
  }

  toggleSidenav(evt: Event) {
    evt.preventDefault();
    document.body.classList.toggle('sidenav-toggled');
  }
}
