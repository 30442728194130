import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
// https://plausible.io/docs/custom-event-goals#trigger-custom-events-manually-with-a-javascript-function
export class PlausibleService {

  constructor() {
    if (environment.production) {
      // @ts-ignore
      window.plausible = window.plausible || function() {
        // @ts-ignore
        (window.plausible.q = window.plausible.q || []).push(arguments)
      }
    }
  }

  trackEvent(event: string) {
    if (environment.production) {
      // @ts-ignore
      window.plausible(event);
    } else {
      console.warn(`Ignoring tracking event: ${event} (non-PROD environment)`);
    }
  }
}
