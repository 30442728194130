import { Component } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { Observable } from "rxjs";
import { Account } from "../../models/account";
import { Router } from "@angular/router";
import { AppUrls } from "../../app-urls";
import { SidenavService } from "../../services/sidenav.service";

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent {

  $account: Observable<Account | undefined>;

  constructor(private auth: AuthService,
              private sidenav: SidenavService,
              private router: Router) {
    this.$account = auth.account;
  }

  onSidebarToggled(evt: Event) {
    this.sidenav.toggleSidenav(evt);
  }

  signOut() {
    this.auth.signOut();
    return this.router.navigate([AppUrls.Root, AppUrls.Login]);
  }
}
