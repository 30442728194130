import { License } from "../models/license";
import { JwtHelperService } from "@auth0/angular-jwt";
import { DateUtil } from "../utils/date-util";
import { DateTime } from "luxon";
import { warnIfRemainingDaysBeforeLicenseExpirationBelow } from "../utils/constants";

export class LicenseViewModel {

  licenseId: string;
  creationDate: string;
  scope: string[];
  showLicenseKey: boolean;
  licenseKey: string;
  state: string;

  offline: boolean;
  analyticsOptOut: boolean;
  customOverlayLogo: boolean;

  expirationDate?: string;

  private _expiresSoon: boolean;
  private _expired: boolean;

  constructor(public license: License,
              jwtHelper: JwtHelperService) {
    this.showLicenseKey = false;
    this.licenseKey = license.key;
    this.licenseId = license.id;
    this.creationDate = DateUtil.formatISODate(license.creation_time);
    this.scope = license.scope;
    this.state = license.state;

    // parse capabilities from JWT payload
    const licensePayload = jwtHelper.decodeToken(license.key);
    if (licensePayload && ('capabilities' in licensePayload)) {
      const capabilities = licensePayload['capabilities'];
      this.offline = capabilities['offline'] ?? false;
      this.analyticsOptOut = capabilities['analyticsOptOut'] ?? false;
      this.customOverlayLogo = capabilities['customOverlayLogo'] ?? false;
    } else {
      this.offline = false;
      this.analyticsOptOut = false;
      this.customOverlayLogo = false;
    }

    // parse expiration date, if it's an offline key
    if (this.offline && ('exp' in licensePayload)) {
      this.expirationDate = DateUtil.formatTimestampDate(licensePayload['exp']);
      const expiresInDays = DateTime.fromSeconds(licensePayload['exp']).diffNow('days').days;
      this._expiresSoon = expiresInDays > 0 && expiresInDays < warnIfRemainingDaysBeforeLicenseExpirationBelow;
      this._expired = expiresInDays <= 0;
    } else {
      this.expirationDate = undefined;
      this._expiresSoon = false;
      this._expired = false;
    }
  }

  get toggleButtonText(): string {
    return this.showLicenseKey ? 'Hide license key' : 'Show license key';
  }

  toggleLicenseKey(): void {
    this.showLicenseKey = !this.showLicenseKey;
  }

  get expiresSoon(): boolean {
    return this._expiresSoon;
  }

  get expired(): boolean {
    return this._expired;
  }
}
