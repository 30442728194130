import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { finalize, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { ApiService } from "../../services/api.service";
import { datesFromRange, UsageDateRange, UsageViewModel } from "../../view-models/usage.viewmodel";
import { Chart, Colors } from "chart.js/auto";
import { hideBootstrapModal, showBootstrapModal } from '../../utils/modal-util';

@Component({
  selector: 'app-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss']
})
export class UsageComponent implements OnInit, OnDestroy {

  viewModel?: UsageViewModel;

  loading = false;
  errorMessage?: string;
  usageChart?: Chart;
  osPieChart?: Chart;
  browserPieChart?: Chart;
  busyHoursChart?: Chart;
  symbologyChart?: Chart;
  weekdaysChart?: Chart;

  // make enum accessible in template
  DateRange = UsageDateRange;

  range: UsageDateRange;

  // CSV export
  exporting = false;
  exportRangeText = '';
  exportButtonText = '';

  private accountSubscription?: Subscription;

  @ViewChild('usageChart',  { static: false }) usageChartElem?: ElementRef<HTMLCanvasElement>;
  @ViewChild('osChart',  { static: false }) osChartElem?: ElementRef<HTMLCanvasElement>;
  @ViewChild('browserChart',  { static: false }) browserChartElem?: ElementRef<HTMLCanvasElement>;
  @ViewChild('busyHoursChart',  { static: false }) busyHoursChartElem?: ElementRef<HTMLCanvasElement>;
  @ViewChild('symbologyChart',  { static: false }) symbologyChartElem?: ElementRef<HTMLCanvasElement>;
  @ViewChild('weekdaysChart',  { static: false }) weekdaysChartElem?: ElementRef<HTMLCanvasElement>;

  constructor(private auth: AuthService,
              private ngZone: NgZone,
              private api: ApiService) {
    this.range = UsageDateRange.LAST_30_DAYS;
  }

  ngOnInit(): void {
    Chart.register(Colors);
    this.accountSubscription = this.auth.account.subscribe({
      next: account => {
        if (account) {
          setTimeout(() => {
            this.refreshScanEvents();
          });
        }
      }
    })
  }

  ngOnDestroy(): void {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }

  setRange(range: UsageDateRange) {
    this.range = range;
    this.refreshScanEvents();
  }

  exportToCSV() {
    this.exporting = false;
    this.exportButtonText = 'Export to CSV';
    const [fromDate, toDate] = datesFromRange(this.range);
    this.exportRangeText = `${fromDate.toISODate()} – ${toDate.toISODate()}`
    showBootstrapModal('csvExportModal', 'static', false);
  }

  doExportToCSV() {
    this.exporting = true;
    this.exportButtonText = 'Exporting…';

    const [fromDate, toDate] = datesFromRange(this.range);
    this.api.exportScansToCSV(fromDate.toJSDate(), toDate.toJSDate()).subscribe({
      next: scanData => {
        // see: https://web.dev/patterns/files/save-a-file
        const blob = new Blob([scanData], { type: 'text/csv'});
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = 'export.csv';
        a.style.display = 'none';
        document.body.append(a);
        a.click();
        setTimeout(() => {
          URL.revokeObjectURL(blobUrl);
          a.remove();
        }, 1000);
        setTimeout(() => {
          hideBootstrapModal('csvExportModal');
        }, 1000);
      },
      error: err => {
        console.error(`Failed to export to CSV`, err);
      }
    });
  }

  private refreshScanEvents() {
    this.loading = true;
    const [fromDate, toDate] = datesFromRange(this.range);
    this.api.getScanStats(fromDate.toJSDate(), toDate.toJSDate()).pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: scanStats => {
        this.viewModel = new UsageViewModel(this.range, scanStats);
        this.updateCharts();
      },
      error: err => {
        this.errorMessage = `Failed to load scan events`;
      }
    });
  }

  private updateCharts() {
    this.createOrUpdateUsageChart();
    this.createOrUpdateOSChart();
    this.createOrUpdateBrowserChart();
    this.createOrUpdateBusyHoursChart();
    this.createOrUpdateSymbologyChart();
    this.createOrUpdateWeekdaysChart();
  }

  private createOrUpdateSymbologyChart() {
    if (!this.symbologyChartElem || !this.viewModel) {
      return;
    }

    if (!this.symbologyChart) {
      this.symbologyChart = new Chart(this.symbologyChartElem.nativeElement, {
        type: 'pie',
        data: {
          labels: this.viewModel.symbologyDataSetLabels,
          datasets: [
            {
              label: ' scans',
              data: this.viewModel.symbologyDataSetValues,
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // @ts-ignore
            legend: {
              display: true,
              position: 'right',
              align: 'start'
            }
          }
        }
      });
    } else {
      // update chart
      this.symbologyChart.data.labels = this.viewModel.symbologyDataSetLabels;
      this.symbologyChart.data.datasets = [{
        label: ' scans',
        data: this.viewModel.symbologyDataSetValues,
      }];
      this.symbologyChart.update();
    }
  }

  private createOrUpdateWeekdaysChart() {
    if (!this.weekdaysChartElem || !this.viewModel) {
      return;
    }

    if (!this.weekdaysChart) {
      this.weekdaysChart = new Chart(this.weekdaysChartElem.nativeElement, {
        type: 'bar',
        data: {
          labels: this.viewModel.weekdaysDataSetLabels,
          datasets: [
            {
              label: '% of total scans',
              data: this.viewModel.weekdaysDataSetValues,
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              ticks: {
                callback: (tickValue, index, ticks) => {
                  return tickValue + '%';
                }
              }
            }
          }
        }
      });
    } else {
      // update chart
      this.weekdaysChart.data.labels = this.viewModel.weekdaysDataSetLabels;
      this.weekdaysChart.data.datasets = [{
        label: '% of total scans',
        data: this.viewModel.weekdaysDataSetValues,
      }];
      this.weekdaysChart.update();
    }
  }

  private createOrUpdateBusyHoursChart() {
    if (!this.busyHoursChartElem || !this.viewModel) {
      return;
    }

    if (!this.busyHoursChart) {
      this.busyHoursChart = new Chart(this.busyHoursChartElem.nativeElement, {
        type: 'bar',
        data: {
          labels: this.viewModel.busyHoursDataSetLabels,
          datasets: [
            {
              label: '% of total scans',
              data: this.viewModel.busyHoursDataSetValues,
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              display: false
            }
          },
          scales: {
            x: {
              ticks: {
                callback: (tickValue, index, ticks) => {
                  if (index % 3 === 0) {
                    // draw ticks/axis labels at 0am, 3am, 6am, ... 6pm, 9pm
                    return tickValue;
                  } else {
                    return null;
                  }
                }
              }
            },
            y: {
              ticks: {
                callback: (tickValue, index, ticks) => {
                  return tickValue + '%';
                }
              }
            }
          }
        }
      });
    } else {
      // update chart
      this.busyHoursChart.data.labels = this.viewModel.busyHoursDataSetLabels;
      this.busyHoursChart.data.datasets = [{
        label: '% of total scans',
        data: this.viewModel.busyHoursDataSetValues,
      }];
      this.busyHoursChart.update();
    }
  }

  private createOrUpdateBrowserChart() {
    if (!this.browserChartElem || !this.viewModel) {
      return;
    }

    if (!this.browserPieChart) {
      this.browserPieChart = new Chart(this.browserChartElem.nativeElement, {
        type: 'pie',
        data: {
          labels: this.viewModel.browserChartDataSetLabels,
          datasets: [
            {
              label: ' Scans',
              data: this.viewModel.browserChartDataSetValues,
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // @ts-ignore
            legend: {
              display: true,
              position: 'right',
              align: 'start'
            }
          }
        }
      });
    } else {
      // update chart
      this.browserPieChart.data.labels = this.viewModel.browserChartDataSetLabels;
      this.browserPieChart.data.datasets = [{
        label: ' Scans',
        data: this.viewModel.browserChartDataSetValues,
      }];
      this.browserPieChart.update();
    }
  }

  private createOrUpdateOSChart() {
    if (!this.osChartElem || !this.viewModel) {
      return;
    }

    if (!this.osPieChart) {
      this.osPieChart = new Chart(this.osChartElem.nativeElement, {
        type: 'pie',
        data: {
          labels: this.viewModel.osChartDataSetLabels,
          datasets: [
            {
              label: ' Scans',
              data: this.viewModel.osChartDataSetValues,
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // @ts-ignore
            legend: {
              display: true,
              position: 'right',
              align: 'start'
            }
          }
        }
      });
    } else {
      // update chart
      this.osPieChart.data.labels = this.viewModel.osChartDataSetLabels;
      this.osPieChart.data.datasets = [{
        label: ' Scans',
        data: this.viewModel.osChartDataSetValues,
      }];
      this.osPieChart.update();
    }
  }

  private createOrUpdateUsageChart() {
    if (!this.usageChartElem || !this.viewModel) {
      return;
    }

    if (!this.usageChart) {

      // see: https://www.chartjs.org/docs/latest/charts/line.html
      this.usageChart = new Chart(this.usageChartElem.nativeElement, {
        type: 'line',
        data: {
          labels: this.viewModel.chartDataSetLabels,
          datasets: [
            {
              label: ' scans',
              data: this.viewModel.chartDataSetValues,
              backgroundColor: 'rgba(0, 97, 242, 0.25)',
              borderColor: '#0061f2',
              borderWidth: 1,
              fill: true,
              tension: 0,
              borderCapStyle: 'square',
              pointRadius: 1
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true
            },
            x: {
              display: false
            }
          },
          plugins: {
            // @ts-ignore
            legend: {
              display: false
            }
          }
        }
      });
    } else {
      // update chart
      this.usageChart.data.labels = this.viewModel.chartDataSetLabels;
      this.usageChart.data.datasets = [{
        label: ' scans',
        data: this.viewModel.chartDataSetValues,
        backgroundColor: 'rgba(0, 97, 242, 0.25)',
        borderColor: '#0061f2',
        borderWidth: 1,
        fill: true,
        tension: 0,
        pointRadius: 1
      }];
      this.usageChart.update();
    }
  }
}
