<div id="layoutAuthentication">
  <div id="layoutAuthentication_content">
    <main>
      <div class="container-xl px-4 mt-10">
        <div class="row justify-content-center">
          <div class="col-lg-6">

            <a href="https://strich.io">
              <img src="assets/img/strich-logo-navbar.svg" width="139" height="30" alt="STRICH Logo">
            </a>

            <div *ngIf="!verificationCodeSent" class="card shadow-lg border-0 rounded-lg mt-4">
              <div class="card-header justify-content-center">
                <h1 class="fw-light my-2">Create Account</h1>
              </div>
              <div class="card-body">

                <div class="mb-4">
                  To create a license key and start adding STRICH to your apps, an account is
                  required.
                </div>

                <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                  {{ errorMessage }}
                </div>

                <form [formGroup]="formGroupEmail" (ngSubmit)="signUp()">
                  <div class="mb-3">
                    <label class="small mb-1" for="emailAddress">Email</label>
                    <input class="form-control" id="emailAddress" type="email" aria-describedby="emailHelp"
                           placeholder="Enter email address" autocapitalize="none"
                           formControlName="email" autocomplete="email"/>
                  </div>
                  <button [disabled]="busy || !formGroupEmail.valid" class="btn btn-primary btn-block" type="submit">Create Account</button>

                  <div class="text-sm mt-4">
                    A verification code will be sent to the address in the next step.
                  </div>

                  <div class="row gx-3 mt-3">
                    <div class="col-md-12">
                      <span class="text-xs">
                        By creating an account, you agree to our <a routerLink="/privacy-policy">Privacy Policy</a>.
                      </span>
                    </div>
                  </div>
                </form>
              </div>

              <div class="card-footer text-center">
                <div class="small"><a routerLink="/login">Have an account? Go to login</a></div>
              </div>
            </div>

            <!-- Step 2: enter email confirmation code -->
            <div *ngIf="verificationCodeSent && !emailVerified" class="card shadow-lg border-0 rounded-lg mt-4">
              <div class="card-header justify-content-center">
                <h3 class="fw-light my-4">Verify Email Address</h3>
              </div>
              <div class="card-body">

                <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                  {{ errorMessage }}
                </div>

                <p class="card-text">
                  We sent a verification code to the email address <strong>{{ email }}</strong>.
                  It may take up to a couple of minutes for the email to arrive. If it does not, please check your spam
                  folder or alternatively press <em>Resend verification code</em>.
                </p>
                <form [formGroup]="formGroupEmailVerification" (ngSubmit)="verifyEmail()">
                  <div class="row gx-3">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="small mb-1" for="verificationCode">Verification code</label>
                        <input class="form-control" id="verificationCode" type="text"
                               placeholder="Enter verification code"
                               formControlName="verificationCode"
                               autocomplete="one-time-code"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row gx-3">
                    <div class="col-auto">
                      <button [disabled]="busy || !formGroupEmailVerification.valid" class="btn btn-primary" type="submit">
                        Verify email address
                      </button>
                    </div>
                    <div class="col-auto">
                      <button class="btn btn-link" (click)="resendVerificationCode()">
                        {{ resendVerificationCodeButtonLabel }}
                      </button>
                    </div>
                  </div>


                  <div class="row gx-3 mt-3">
                    <div class="col-md-12">
                      <span class="text-xs">
                        By signing up, you agree to our <a routerLink="/privacy-policy">Privacy Policy</a>.
                      </span>
                    </div>
                  </div>
                </form>
              </div>

              <div class="card-footer text-center">
                <div class="small"><a routerLink="/login">Have an account? Go to login</a></div>
              </div>
            </div>


            <!-- Step 3: Set password -->
            <div *ngIf="verificationCodeSent && emailVerified" class="card shadow-lg border-0 rounded-lg mt-4">
              <div class="card-header justify-content-center"><h3 class="fw-light my-4">Set Password</h3></div>
              <div class="card-body">
                <p class="card-text">
                  You successfully verified your email address. Set a password for your account now.
                </p>
                <form [formGroup]="formGroupPassword" (ngSubmit)="setPassword()">
                  <div class="row gx-3">
                    <div class="col">
                      <div class="mb-3">
                        <label class="small mb-1" for="newPassword">Password</label>
                        <input class="form-control" id="newPassword" type="password" placeholder="Enter password"
                               autocomplete="new-password"
                               formControlName="newPassword"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row gx-3 mt-3">
                    <div class="col">
                      <div class="mb-3">
                        <label class="small mb-1" for="newPasswordConfirmation">Confirm Password</label>
                        <input class="form-control" id="newPasswordConfirmation" type="password"
                               autocomplete="new-password" formControlName="newPasswordConfirmation"
                               placeholder="Confirm password"/>
                      </div>
                    </div>
                  </div>
                  <button [disabled]="busy || !formGroupPassword.valid" class="btn btn-primary btn-block" type="submit">Set password</button>
                </form>
              </div>

              <div class="card-footer text-center">
                <div class="small"><a routerLink="/login">Have an account? Go to login</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div id="layoutAuthentication_footer">
    <app-footer></app-footer>
  </div>
</div>

