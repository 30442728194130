<nav class="topnav navbar navbar-expand shadow justify-content-between justify-content-sm-start navbar-light bg-white" id="sidenavAccordion">
  <!-- Sidenav Toggle Button-->
  <button class="btn btn-icon btn-transparent-dark order-1 order-lg-0 me-2 ms-lg-2 me-lg-0" id="sidebarToggle"
          (click)="onSidebarToggled($event)">
    <i-feather name="menu" class="menu-button"></i-feather>
  </button>

  <!-- Navbar Brand-->
  <a class="pe-3 ps-4 ps-lg-2" routerLink="/home">
    <img src="/assets/img/strich-logo-navbar.svg" width="auto" height="20" alt="STRICH Logo">
  </a>
  <!-- Navbar Items-->
  <ul class="navbar-nav align-items-center ms-auto">
    <!-- User Dropdown-->
    <li *ngIf="$account | async as account" class="nav-item dropdown no-caret dropdown-user me-3 me-lg-4">
      <a class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" [routerLink]=""
         role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <img class="img-fluid" src="/assets/img/user-placeholder.svg" />
      </a>
      <div class="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">
        <h6 class="dropdown-header d-flex align-items-center">
          <img class="dropdown-user-img" src="/assets/img/user-placeholder.svg" />
          <div class="dropdown-user-details">
            <div class="dropdown-user-details-name">Logged in as:</div>
            <div class="dropdown-user-details-email">{{ account.shortenedEmail }}</div>
          </div>
        </h6>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" [routerLink]="" (click)="signOut()">
          <div class="dropdown-item-icon"><i-feather style="width: 16px; height: 16px;" name="log-out"></i-feather></div>
          Sign out
        </a>
      </div>
    </li>
  </ul>
</nav>
