import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../../services/api.service";
import { HttpErrorResponse } from "@angular/common/http";
import { finalize } from "rxjs";

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent {

  busy: boolean;
  changePasswordForm: FormGroup;
  showSuccess: boolean;
  errorMessage?: string;

  constructor(private formBuilder: FormBuilder,
              private api: ApiService) {
    this.busy = false;
    this.showSuccess = false;
    this.changePasswordForm = formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      newPasswordConfirmation: ['', Validators.required]
    });
  }

  changePassword(): void {
    this.busy = true;
    this.errorMessage = undefined;
    this.showSuccess = false;
    this.api.changePassword(
      this.changePasswordForm.value.oldPassword,
      this.changePasswordForm.value.newPassword
    ).pipe(
      finalize(() => { this.busy = false; })
    ).subscribe({
      next: _ => {
        this.showSuccess = true;
      },
      error: err => {
        if (err instanceof HttpErrorResponse) {
          if (err.error && 'message' in err.error) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = err.message;
          }
        } else {
          this.errorMessage = err.toString();
        }
      }
    });
  }

}
