import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { finalize, forkJoin, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "../../services/api.service";
import { PaddleService } from "../../services/paddle.service";
import { SubscriptionViewModel } from "../../view-models/subscription.viewmodel";
import { hideBootstrapModal, showBootstrapModal } from "../../utils/modal-util";
import { PlausibleService } from "../../services/plausible.service";

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnDestroy {

  viewModel?: SubscriptionViewModel;

  loading = false;
  errorMessage?: string;

  fromCreateSubscription = false;
  fromUpgradeSubscription = false;

  private subscription?: Subscription;

  constructor(private auth: AuthService,
              private plausible: PlausibleService,
              private api: ApiService,
              private paddle: PaddleService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.fromCreateSubscription = !!params['fromCreateSubscription'];
    });
    this.loading = true;
    this.subscription = this.auth.account.subscribe((account) => {

      if (!account) {
        return; // shouldn't happen, it's an authenticated route
      }

      if (account.isPaddle) {
        // fetch subscription usage and paddle user info concurrently
        forkJoin([this.api.getSubscriptionUsage(), this.api.getPaddleUserInfo()]).pipe(
          finalize(() => this.loading = false)
        ).subscribe({
          next: (usageAndPaddleInfos) => {
            const subscriptionUsage = usageAndPaddleInfos[0];
            const paddleInfos = usageAndPaddleInfos[1];
            this.viewModel = new SubscriptionViewModel(account, paddleInfos, subscriptionUsage);
          },
          error: err => {
            this.errorMessage = `Failed to fetch subscription information. Please try again later or contact support if the issue persists.`;
            this.viewModel = undefined;
          }
        });
      } else {
        // custom/enterprise subscription
        this.api.getSubscriptionUsage().pipe(
          finalize(() => this.loading = false)
        ).subscribe({
          next: usage => {
            this.viewModel = new SubscriptionViewModel(account, undefined, usage);
          }
        })
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  updatePaymentMethod() {
    if (!this.viewModel?.account) {
      return;
    }
    this.paddle.updatePaymentMethod(this.viewModel.account).finally(() => {
      return this.auth.refreshAccount();
    });
  }


  upgradeToProfessional() {
    showBootstrapModal('upgradeSubscriptionModal');
  }

  doUpgradeToProfessional() {
    hideBootstrapModal('upgradeSubscriptionModal');

    if (!this.viewModel?.account?.canUpgradeToProfessional) {
      return;
    }

    this.api.upgradeToProfessional().subscribe({
      next: () => {

        this.plausible.trackEvent('SubscriptionUpgraded');

        // Add a slight delay to allow for the webhook data to arrive
        setTimeout(() => {
          this.fromUpgradeSubscription = true;
          return this.auth.refreshAccount(); // page will update
        }, 1_500);
      },
      error: err => {
        this.errorMessage = `Failed to upgrade subscription: ${err}`;
      }
    });
  }

  cancelSubscription() {
    if (!this.viewModel?.account) {
      return;
    }

    this.paddle.cancelSubscription(this.viewModel.account)
      .then((cancelled) => {
        if (!cancelled) {
          // user closed dialog without cancelling
          return Promise.resolve();
        }

        // subscription was cancelled, refresh account (update page)
        return this.auth.refreshAccount().then(() => {});
      });
  }
}
