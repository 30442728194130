import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { Account } from "../../models/account";
import { AuthService } from "../../services/auth.service";
import { environment } from "../../../environments/environment";
import { PaddleService } from "../../services/paddle.service";
import { Router } from "@angular/router";
import { AppUrls } from "../../app-urls";
import { PlausibleService } from "../../services/plausible.service";

@Component({
  selector: 'app-create-subscription',
  templateUrl: './create-subscription.component.html',
  styleUrls: ['./create-subscription.component.scss']
})
export class CreateSubscriptionComponent implements OnInit, OnDestroy {

  account?: Account;
  hasAcceptedLicenseAgreement = false;

  startBasicTrialPendingAcceptance = false;
  startProTrialPendingAcceptance = false;

  private subscription?: Subscription;

  constructor(private auth: AuthService,
              private paddle: PaddleService,
              private plausible: PlausibleService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.subscription = this.auth.account.subscribe((account) => {
      this.account = account;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  startBasicTrial(): void {
    if (this.hasAcceptedLicenseAgreement) {
      this.createSubscription(environment.paddle.subscriptionPlanIds.basic);
    } else {
      this.startBasicTrialPendingAcceptance = true;
      this.showLicenseAgreementModal();
    }
  }

  startProTrial(): void {
    if (this.hasAcceptedLicenseAgreement) {
      this.createSubscription(environment.paddle.subscriptionPlanIds.pro);
    } else {
      this.startProTrialPendingAcceptance = true;
      this.showLicenseAgreementModal();
    }
  }

  /**
   * Invoked by the modal.
   */
  acceptLicenseAgreement(): void {
    const modalElem = document.getElementById('licenseAgreementModal')
    if (modalElem === null) {
      return;
    }
    const modal = (window as any).bootstrap.Modal.getInstance(modalElem);
    modal?.hide();

    // mark as accepted
    this.hasAcceptedLicenseAgreement = true;

    // fulfill pending start trial request
    if (this.startProTrialPendingAcceptance) {
      this.startProTrial();
    } else if (this.startBasicTrialPendingAcceptance) {
      this.startBasicTrial();
    }
    this.startBasicTrialPendingAcceptance = false;
    this.startProTrialPendingAcceptance = false;
  }

  private showLicenseAgreementModal(): void {
    const modalElem = document.getElementById('licenseAgreementModal');
    if (modalElem === null) {
      return;
    }
    // show Bootstrap modal using JS api
    const modal = new (window as any).bootstrap.Modal(modalElem);
    modal.show();
  }

  private createSubscription(planId: string) {
    if (!this.account) {
      return;
    }

    this.paddle.createSubscription(planId, this.account.id, this.account.email, () => {
      this.plausible.trackEvent('TrialStarted');

      // Add a slight delay to allow for the webhook data to arrive
      setTimeout(() => {
        this.auth.refreshAccount().then((account) => {
          this.account = account;
          return this.router.navigate([AppUrls.Root, AppUrls.Subscription], {
            queryParams: {
              fromCreateSubscription: true
            }
          });
        }).catch(err => {
          console.error(`Failed to refresh account data after Paddle success callback`, err);
        })
      }, 1_500);
    }, () => {
      // Paddle overlay closed
    });
  }
}
