import { DateTime } from "luxon";

export class DateUtil {

  /**
   * Get formatted date from ISO8601 string.
   *
   * @param isoStr ISO8601 date time
   */
  static formatISODate(isoStr: string): string {
    const dateTime = DateTime.fromISO(isoStr);
    return dateTime.toISODate();
  }

  /**
   * Get formatted date from Unix timestamp (seconds since epoch)
   *
   * @param timestamp Seconds since epoch
   */
  static formatTimestampDate(timestamp: number): string {
    const dateTime = DateTime.fromSeconds(timestamp);
    return dateTime.toISODate();
  }

  static formatISODateTime(isoStr: string): string {
    const dateTime = DateTime.fromISO(isoStr);
    return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
  }

  static dateTimeFromYYYYMMDD(yyyymmdd: string, timezone: string): DateTime {
    return DateTime.fromISO(yyyymmdd, { zone: timezone });
  }

  static dateTimeToYYYYMMDD(dateTime: DateTime): string {
    return dateTime.toFormat('yyyy-MM-dd');
  }
}
