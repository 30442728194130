import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { AuthService } from "../../services/auth.service";
import { LicenseService } from "../../services/license.service";
import { Account } from "../../models/account";
import { hideBootstrapModal, showBootstrapModal } from "../../utils/modal-util";
import { AppUrls } from "../../app-urls";
import { PaddleService } from "../../services/paddle.service";
import { environment } from "../../../environments/environment";
import { PlausibleService } from "../../services/plausible.service";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  account?: Account = undefined;
  hasSubscription?: boolean;
  subscriptionIconName: string;
  showExpiringLicenseKeysWarning = false;

  // when coming from signup, display a welcome dialog and prompt to start trial
  fromSignup = false;
  hasAcceptedLicenseAgreement = false;

  private accountSubscription?: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private auth: AuthService,
              private license: LicenseService,
              private paddle: PaddleService,
              private plausible: PlausibleService
  ) {
    this.subscriptionIconName = 'check-circle';
  }

  ngOnInit() {
    this.accountSubscription = this.auth.account.subscribe({
      next: account => {
        this.account = account;
        this.hasSubscription = account?.hasSubscription;
        this.subscriptionIconName = account?.hasActiveSubscription ? 'check-circle' : 'alert-triangle';

        // show a warning message if the account has license keys that will expire in the next 60 days.
        if (account) {
          this.showExpiringLicenseKeysWarning = false;
          for (const license of account.activeLicenses) {
            if (this.license.isLicenseExpiringSoon(account, license)) {
              this.showExpiringLicenseKeysWarning = true;
              break;
            }
          }
        }
      }
    });
    this.account = this.auth.account.value;
    this.fromSignup = this.route.snapshot.queryParamMap.get('fromSignup') !== null;
    if (this.fromSignup) {
      setTimeout(() => {
        this.showStartTrialModal();
      });
    }
    return this.auth.refreshAccount();
  }

  ngOnDestroy() {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
      this.accountSubscription = undefined;
    }
  }

  showStartTrialModal() {
    showBootstrapModal('startTrialModal', true);
  }

  startTrialFromWelcomeModal() {
    hideBootstrapModal('startTrialModal');

    if (!this.account) {
      return; // shouldn't happen
    }

    this.paddle.createSubscription(environment.paddle.subscriptionPlanIds.basic, this.account.id, this.account.email, () => {
      this.plausible.trackEvent('TrialStarted');

      // Add a slight delay to allow for the webhook data to arrive
      setTimeout(() => {
        this.auth.refreshAccount().then((account) => {
          this.account = account;
          return this.router.navigate([AppUrls.Root, AppUrls.Subscription], {
            queryParams: {
              fromCreateSubscription: true
            }
          });
        }).catch(err => {
          console.error(`Failed to refresh account data after Paddle success callback`, err);
        })
      }, 1_500);
    }, () => {
      // Paddle overlay closed
    });
  }
}
