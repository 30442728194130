<app-topnav></app-topnav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content">
    <main>
      <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
        <div class="container-xl px-4">
          <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto mt-4">
                <h1 class="page-header-title">
                  Your purchase was successful
                </h1>
                <div class="page-header-subtitle">Thank you for your business.</div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!-- Main page content-->
      <div class="container-xl px-4 mt-n10">
        <div class="card">
          <div class="card-body">
            <p>
              We are activating your purchase now. It may take a couple of minutes before you
              have access to the purchased features.

              <br><br>
              Thank you so much for supporting our business!
              <br>
              - The team @ Pixelverse
            </p>

            <a routerLink="/dashboard">
              <button class="btn btn-primary">Go to dashboard</button>
            </a>
          </div>
        </div>
      </div>
    </main>
    <app-footer></app-footer>
  </div>
</div>
