import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppUrls } from "../app-urls";

/**
 * Interceptor that handles 401/403 responses from the backend.
 */
@Injectable()
export class AuthenticationErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,
              private auth: AuthService) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status == 403) {
      console.log(`Received HTTP ${err.status}, clearing stored authentication and showing login page`);
      this.auth.signOut();
      this.router.navigate([AppUrls.Root, AppUrls.Login]);
      return throwError(() => err);
    }

    // bubble up anything else
    return throwError(() => err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(x => this.handleAuthError(x))
    );
  }
}
