export class NumberFormatUtil {

  static formatScanCount(count: number): string {
    if (count < 1000) {
      return count.toString();
    } else if (count < 1000000) {
      const thousands = count / 1000.0;
      return `${parseFloat(thousands.toFixed(1))}K`; // https://stackoverflow.com/a/17556028/1370154
    } else {
      const millions = count / 1000000.0;
      return `${parseFloat(millions.toFixed(2))}M`; // https://stackoverflow.com/a/17556028/1370154
    }
  }

  static formatPercentage(pct: number): string {
    if (pct < 0.01) {
      return '< 1%';
    } else {
      return (pct * 100).toFixed(1) + '%';
    }
  }
}
