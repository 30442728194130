<app-topnav></app-topnav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content">


    <main>
      <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
        <div class="container-xl px-4">
          <div class="page-header-content">
            <div class="row align-items-center justify-content-between pt-3">
              <div class="col-auto mb-3">
                <h1 class="page-header-title">
                  <div class="page-header-icon"><i-feather name="user"></i-feather></div>
                  Account Settings - Notifications
                </h1>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!-- Main page content-->
      <div class="container-xl px-4 mt-4">
        <!-- Account page navigation-->
        <nav class="nav nav-borders">
          <a class="nav-link" routerLink="/account/security">Security</a>
          <a class="nav-link active" routerLink="/account/notifications">Notifications</a>
        </nav>
        <hr class="mt-0 mb-4"/>
        <div class="row">
          <div class="col-lg-8">
            <!-- Email notifications preferences card-->
            <div class="card card-header-actions mb-4">
              <div class="card-header">
                Email Notifications
              </div>
              <div class="card-body">

                Get notified of upcoming payments, license thresholds reached, and more. Coming soon!

                <!--

                <form>
                  <div class="mb-3">
                    <label class="small mb-1" for="inputNotificationEmail">Default notification email</label>
                    <input class="form-control" id="inputNotificationEmail" type="email" value="name@example.com"
                           disabled/>
                  </div>
                  <div class="mb-0">
                    <label class="small mb-2">Choose which types of email updates you receive</label>
                    <div class="form-check mb-2">
                      <input class="form-check-input" id="checkAccountChanges" type="checkbox" checked/>
                      <label class="form-check-label" for="checkAccountChanges">Changes made to your account</label>
                    </div>
                    <div class="form-check mb-2">
                      <input class="form-check-input" id="checkAccountGroups" type="checkbox" checked/>
                      <label class="form-check-label" for="checkAccountGroups">Changes are made to groups you're part
                        of</label>
                    </div>
                    <div class="form-check mb-2">
                      <input class="form-check-input" id="checkProductUpdates" type="checkbox" checked/>
                      <label class="form-check-label" for="checkProductUpdates">Product updates for products you've
                        purchased or starred</label>
                    </div>
                    <div class="form-check mb-2">
                      <input class="form-check-input" id="checkProductNew" type="checkbox" checked/>
                      <label class="form-check-label" for="checkProductNew">Information on new products and
                        services</label>
                    </div>
                    <div class="form-check mb-2">
                      <input class="form-check-input" id="checkPromotional" type="checkbox"/>
                      <label class="form-check-label" for="checkPromotional">Marketing and promotional offers</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" id="checkSecurity" type="checkbox" checked disabled/>
                      <label class="form-check-label" for="checkSecurity">Security alerts</label>
                    </div>
                  </div>
                </form>

                -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <app-footer></app-footer>
  </div>
</div>

