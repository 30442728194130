import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { License } from "../models/license";
import { DateTime } from "luxon";
import { Account } from "../models/account";
import { warnIfRemainingDaysBeforeLicenseExpirationBelow } from "../utils/constants";
import { LicenseViewModel } from "../view-models/license.viewmodel";

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(private jwtHelper: JwtHelperService) {
  }

  isLicenseExpiringSoon(account: Account, license: License): boolean {
    if (license.state !== 'active') {
      return false;
    }

    // TODO: shouldn't use a view model in a service, refactor
    const licenseVM = new LicenseViewModel(license, this.jwtHelper);
    if (licenseVM.offline) {
      const expDate = this.jwtHelper.getTokenExpirationDate(license.key);
      if (expDate) {
        const expiresInDays = DateTime.fromJSDate(expDate, { zone: account.timezone }).diffNow('days').days;
        if (expiresInDays > 0 && expiresInDays < warnIfRemainingDaysBeforeLicenseExpirationBelow) {
          return true;
        }
      }
    }
    return false;
  }
}
