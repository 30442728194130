<div id="layoutAuthentication">
  <div id="layoutAuthentication_content">
    <main>
      <div class="container-xl px-4 mt-10">
        <div class="row justify-content-center">

          <div class="col-lg-5">

            <a href="https://strich.io">
              <img src="assets/img/strich-logo-navbar.svg" width="139" height="30" alt="STRICH Logo">
            </a>

            <!-- Basic forgot password form-->
            <div class="card shadow-lg border-0 rounded-lg mt-4">
              <div class="card-header justify-content-center">
                <h1 class="fw-light my-2">Forgot Password?</h1>
              </div>
              <div class="card-body">

                <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
                  {{ errorMessage }}
                </div>

                <div class="small mb-3 text-muted">
                  Enter the email address of your account and we will send you a link to reset your password.
                </div>
                <!-- Forgot password form-->
                <form [formGroup]="emailFormGroup" (ngSubmit)="initiatePasswordReset()">
                  <!-- Form Group (email address)-->
                  <div class="mb-3">
                    <label class="small mb-1" for="inputEmailAddress">Email</label>
                    <input class="form-control" id="inputEmailAddress" type="email" aria-describedby="emailHelp"
                           placeholder="user@example.com" autocomplete="email" formControlName="email"/>
                  </div>
                  <!-- Form Group (submit options)-->
                  <div class="d-flex align-items-center justify-content-between mt-4 mb-0">
                    <a class="small" routerLink="/login">Return to login</a>
                    <button class="btn btn-primary" type="submit" [disabled]="!emailFormGroup.valid">Reset Password</button>
                  </div>
                </form>
              </div>

              <div class="card-footer text-center">
                <div class="small"><a routerLink="/register">Need an account? Sign up!</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div id="layoutAuthentication_footer">
    <app-footer></app-footer>
  </div>
</div>
