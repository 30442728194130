<app-topnav></app-topnav>
<div id="layoutSidenav">
  <div id="layoutSidenav_nav">
    <app-sidenav></app-sidenav>
  </div>
  <div id="layoutSidenav_content">
    <main>
      <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-2">
        <div class="container-xl px-4">
          <div class="page-header-content pt-4">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto mt-4">
                <h1 class="page-header-title">
                  Support
                </h1>
                <div class="page-header-subtitle">
                  Need help? You've come to the right place.
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="container-xl px-4 mt-4">
        <div class="row">
          <div class="col">
            <figure>
              <blockquote class="blockquote">
                <p>He who asks is a fool for five minutes, but he who does not ask remains a fool forever.</p>
              </blockquote>
              <figcaption class="blockquote-footer">
                Mark Twain
              </figcaption>
            </figure>
          </div>
        </div>
      </div>

      <div class="container-xl px-4" *ngIf="account$ | async; let account">
        <div class="row">
          <div class="col-md-6 mb-4">
            <div class="card">
              <div class="card-header">
                Support Helpdesk
              </div>
              <div class="card-body">
                <p>
                  Our helpdesk is available on weekdays and typically responds within 24 hours.
                  We observe <a href="https://en.wikipedia.org/wiki/Public_holidays_in_the_United_States#Federal_holidays" target="_blank">US Federal holidays</a>.
                </p>
                <p class="text-muted text-sm">
                  We are 100% human beings with no AI chatbots. As such, you may not got an answer instantly, but rest assured
                  that we will respond as soon as we can.
                </p>
                <div class="mt-2">
                  <button class="btn btn-outline-primary" (click)="openBeacon()">
                    Send Message
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4">
            <div class="card">
              <div class="card-header">
                FAQ
              </div>
              <div class="card-body">
                <p>Have you checked our <a href="https://strich.io/faq.html">Frequently Asked Questions</a> page?</p>
                <p>Maybe your question already has an answer.</p>
              </div>
            </div>
          </div>
        </div>
      </div> <!-- container -->
    </main>
    <app-footer></app-footer>
  </div>
</div>


