import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { BillingComponent } from './components/billing/billing.component';
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { SecurityComponent } from "./components/security/security.component";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { PurchaseCompletedComponent } from "./components/purchase-completed/purchase-completed.component";
import { SubscriptionComponent } from "./components/subscription/subscription.component";
import { CreateLicenseKeyComponent } from "./components/create-license-key/create-license-key.component";
import { LicenseKeysComponent } from "./components/license-keys/license-keys.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { CreateSubscriptionComponent } from "./components/create-subscription/create-subscription.component";
import { SupportComponent } from "./components/support/support.component";
import { UsageComponent } from "./components/usage/usage.component";

import { AuthGuard } from './guards/auth.guard';
import { AppUrls } from "./app-urls";

const routes: Routes = [

  // unauthenticated routes
  {path: AppUrls.Login, component: LoginComponent},
  {path: AppUrls.ForgotPassword, component: ForgotPasswordComponent},
  {path: AppUrls.ResetPassword, component: ResetPasswordComponent},
  {path: AppUrls.Register, component: RegisterComponent},
  {path: AppUrls.PrivacyPolicy, component: PrivacyPolicyComponent},

  // authenticated routes
  {path: AppUrls.Dashboard, component: DashboardComponent, canActivate: [AuthGuard]},
  {path: AppUrls.Subscription, component: SubscriptionComponent, canActivate: [AuthGuard]},
  {path: AppUrls.CreateSubscription, component: CreateSubscriptionComponent, canActivate: [AuthGuard]},
  {path: AppUrls.LicenseKeys, component: LicenseKeysComponent, canActivate: [AuthGuard]},
  {path: AppUrls.CreateLicenseKey, component: CreateLicenseKeyComponent, canActivate: [AuthGuard]},
  {path: AppUrls.Billing, component: BillingComponent, canActivate: [AuthGuard]},
  {path: AppUrls.Security, component: SecurityComponent, canActivate: [AuthGuard]},
  {path: AppUrls.Notifications, component: NotificationsComponent, canActivate: [AuthGuard]},
  {path: AppUrls.PurchaseCompleted, component: PurchaseCompletedComponent, canActivate: [AuthGuard]},
  {path: AppUrls.Support, component: SupportComponent, canActivate: [AuthGuard]},
  {path: AppUrls.Usage, component: UsageComponent, canActivate: [AuthGuard]},

  {path: '', redirectTo: AppUrls.Root + AppUrls.Dashboard, pathMatch: 'full'},
  {path: '**', redirectTo: AppUrls.Dashboard}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false // useful for debugging purposes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
