import { License, LicenseCapabilities } from "./license";
import { environment } from "../../environments/environment";
import { shortenEmail } from "../utils/email-util";
import { DateTime } from "luxon";

export class Account {

  creation_time: string; // e.g. "2022-11-17T10:25:35.820717+00:00"
  id: string; // e.g. "18e4e4a3-b204-45ef-a7cd-68bef43c722c"
  email: string;
  email_verified: boolean;
  state: 'active' | 'email_verification' | 'set_password' | 'password_reset';
  timezone: string;
  last_login_time: string; // e.g. "2022-11-17T13:29:18.515049+00:00"
  licenses: License[];
  portal_role?: string;

  // Custom subscription
  custom_subscription?: string;

  // Paddle integration
  paddle_user_id?: string;
  paddle_sub_id?: string;
  paddle_sub_plan_id?: string;
  paddle_sub_status?: 'active' | 'trialing' | 'past_due' | 'paused' | 'deleted';
  paddle_sub_update_url?: string;
  paddle_sub_cancel_url?: string;
  paddle_cancellation_effective_date?: string // YYYY-MM-DD

  // Enterprise stuff
  ent_contract_start_date?: string;
  ent_contract_end_date?: string;
  ent_contract_reference?: string;
  ent_trial_end_date?: string;

  // License capabilities
  license_capabilities: LicenseCapabilities;
  _hasAnalyticsOptOutCapability: boolean;
  _hasOfflineCapability: boolean;
  _hasCustomOverlayLogoCapability: boolean;
  _hasRelaxedScopeCapability: boolean;
  _hasWildcardScopeCapability: boolean;

  constructor(data: any) {
    this.creation_time = data['creation_time'];
    this.id = data['id'];
    this.email = data['email'];
    this.email_verified = data['email_verified'];
    this.state = data['state'];
    this.last_login_time = data['last_login_time'];
    this.timezone = data['timezone'];
    this.licenses = data['licenses'] as License[];
    this.portal_role = data['portal_role'];
    this.custom_subscription = data['custom_subscription'];
    this.paddle_user_id = data['paddle_user_id'];
    this.paddle_sub_id = data['paddle_sub_id'];
    this.paddle_sub_plan_id = data['paddle_sub_plan_id'];
    this.paddle_sub_status = data['paddle_sub_status'];
    this.paddle_sub_update_url = data['paddle_sub_update_url'];
    this.paddle_sub_cancel_url = data['paddle_sub_cancel_url'];
    this.paddle_cancellation_effective_date = data['paddle_cancellation_effective_date'];
    this.ent_contract_start_date = data['ent_contract_start_date'];
    this.ent_contract_end_date = data['ent_contract_end_date'];
    this.ent_contract_reference = data['ent_contract_reference'];
    this.ent_trial_end_date = data['ent_trial_end_date'];
    this.license_capabilities = data['license_capabilities'] ?? {};
    this._hasOfflineCapability = this.license_capabilities && this.license_capabilities['offline'] === true;
    this._hasAnalyticsOptOutCapability = this.license_capabilities && this.license_capabilities['analyticsOptOut'] === true;
    this._hasCustomOverlayLogoCapability = this.license_capabilities && this.license_capabilities['customOverlayLogo'] === true;
    this._hasRelaxedScopeCapability = this.license_capabilities && this.license_capabilities['relaxedScope'] === true;
    this._hasWildcardScopeCapability = this.license_capabilities && this.license_capabilities['wildcardScope'] === true;
  }

  /**
   * See: https://developer.paddle.com/reference/ZG9jOjI1MzU0MDI2-subscription-status-reference
   */
  get subscriptionState(): string | undefined {
    switch (this.paddle_sub_status) {
      case 'active':
        return 'active';
      case 'trialing':
        return 'trialing';
      case "deleted":
        return 'cancelled';
      case 'paused':
        return 'paused';
      case 'past_due':
        return 'past_due'
      default:
        return undefined;
    }
  }

  /**
   * See: https://getbootstrap.com/docs/5.1/components/badge/#background-colors
   */
  get subscriptionStateBadgeClass(): string {
    switch (this.paddle_sub_status) {
      case 'active':
      case 'trialing':
        return 'badge bg-primary';
      case 'paused':
      case 'past_due':
        return 'badge bg-warning text-dark'
      case "deleted":
        return 'badge bg-danger'
      default:
        return 'badge bg-light text-dark';
    }
  }

  get hasSubscription(): boolean {
    return !!this.paddle_sub_id || !!this.custom_subscription;
  }

  get hasActiveSubscription(): boolean {
    return this.hasActivePaddleSubscription || this.hasCustomSubscription;
  }

  get hasCancelledSubscription(): boolean {
    return this.isPaddle && this.paddle_sub_status === 'deleted';
  }

  get isCancellationEffective(): boolean {
    if (!this.hasCancelledSubscription) {
      return false;
    }

    if (!this.paddle_cancellation_effective_date) {
      return false; // shouldn't happen, as a cancelled subscription always has an effective cancellation date
    }

    // client-side check (not secure, but we don't care, as we check on server too)
    const today = DateTime.now().setZone(this.timezone).startOf('day');
    const effectiveDate = DateTime.fromISO(this.paddle_cancellation_effective_date, { zone: this.timezone }).startOf('day');
    return today >= effectiveDate;
  }

  get subscriptionName(): string | undefined {
    if (this.hasSubscription) {
      if (this.isPaddle) {
        return this.planName;
      } else {
        return this.custom_subscription;
      }
    } else {
      return undefined;
    }
  }

  get isTrial(): boolean {
    return this.hasSubscription && this.paddle_sub_status === 'trialing';
  }

  get hasCustomSubscription(): boolean {
    return !!this.custom_subscription;
  }

  get isEnterprise(): boolean {
    return this.custom_subscription === 'enterprise';
  }

  get isEnterpriseTrial(): boolean {
    return this.custom_subscription === 'enterprise_trial';
  }

  get isPaddle(): boolean {
    return !!this.paddle_sub_id;
  }

  get customSubscriptionType(): string {
    return this.custom_subscription ?? 'unknown';
  }

  get isBasic(): boolean {
    return this.hasSubscription && this.paddle_sub_plan_id === environment.paddle.subscriptionPlanIds.basic;
  }

  get isPro(): boolean {
    return this.hasSubscription && this.paddle_sub_plan_id === environment.paddle.subscriptionPlanIds.pro;
  }

  get hasActivePaddleSubscription(): boolean {
    return this.hasSubscription && (this.paddle_sub_status === 'active' || this.paddle_sub_status === 'trialing');
  }

  get activeLicenses(): License[] {
    return this.licenses.filter(lic => lic.state !== 'deleted');
  }

  get canCreateLicenseKey(): boolean {
    if (this.hasCustomSubscription) {
      // no limitations for non-Paddle customers at the moment
      return true;
    }

    if (this.isPaddle) {
      // active subscription can create license keys
      if (this.hasActivePaddleSubscription) {
        return true;
      }

      // cancelled subscription can create license keys, until the cancellation is effective
      if (this.hasCancelledSubscription) {
        return !this.isCancellationEffective;
      }
    }

    return false;
  }

  get planName(): string | undefined {
    if (!this.paddle_sub_plan_id) {
      return undefined;
    }
    switch (this.paddle_sub_plan_id) {
      case environment.paddle.subscriptionPlanIds.basic:
        return 'STRICH BASIC';
      case environment.paddle.subscriptionPlanIds.pro:
        return 'STRICH PROFESSIONAL';
      default:
        return `PADDLE_${this.paddle_sub_plan_id}`;
    }
  }

  /**
   * See: https://docs.helpscout.com/article/1385-customer-properties
   */
  get helpScoutProperties(): { [key: string]: string } {
    let planName = 'N/A';
    let billingFrequency = 'N/A';
    let paddleSubId = 'N/A';
    let paddleUserId = 'N/A';
    if (this.isEnterprise || this.isEnterpriseTrial) {
      planName = 'Enterprise';
      billingFrequency = 'Annual';
    } else if (this.paddle_sub_plan_id) {
      if (this.paddle_sub_plan_id == environment.paddle.subscriptionPlanIds.basic) {
        planName = 'Basic';
        billingFrequency = 'Monthly';
      } else if (this.paddle_sub_plan_id == environment.paddle.subscriptionPlanIds.pro) {
        planName = 'Professional';
        billingFrequency = 'Monthly';
      }
      paddleSubId = this.paddle_sub_id ?? 'N/A';
      paddleUserId = this.paddle_user_id ?? 'N/A';
    }
    return {
      planName,
      paddleClassicUserId: paddleUserId,
      paddleClassicSubscriptionId: paddleSubId,
      billingFrequency,
      accountId: this.id
    };
  }

  get canUpgradeToProfessional(): boolean {
    return this.hasActivePaddleSubscription && this.isBasic && !this.isTrial;
  }

  /**
   * Indicate if account can be converted to Enterprise.
   */
  get canConvertToEnterprise(): boolean {
    if (this.state !== 'active') {
      return false;
    }

    if (this.isEnterpriseTrial) {
      return true; // Enterprise trials can convert
    }

    if (this.hasCustomSubscription) {
      return false; // Other custom subscriptions can't (pilot, resolve manually)
    }

    if (this.hasActivePaddleSubscription) {
      return false; // Paddle subscriptions need to be in deleted state
    }

    return true;
  }

  /**
   * Indicate if account can start an Enterprise trial
   */
  get canStartEnterpriseTrial(): boolean {
    if (this.isEnterpriseTrial) {
      return false;
    } else {
      return this.canConvertToEnterprise; // same requirements as starting Enterprise subscription
    }
  }

  get hasAnyCustomCapability(): boolean {
    return this._hasOfflineCapability || this._hasAnalyticsOptOutCapability || this._hasCustomOverlayLogoCapability;
  }

  get hasOfflineCapability(): boolean {
    return this._hasOfflineCapability;
  }

  get hasAnalyticsOptOutCapability(): boolean {
    return this._hasAnalyticsOptOutCapability;
  }

  get hasCustomOverlayLogoCapability(): boolean {
    return this._hasCustomOverlayLogoCapability;
  }

  get hasRelaxedScopeCapability(): boolean {
    return this._hasRelaxedScopeCapability;
  }

  get hasWildcardScopeCapability(): boolean {
    return this._hasWildcardScopeCapability;
  }

  get canCancel(): boolean {
    return this.isPaddle && this.paddle_sub_status !== 'deleted' && !!this.paddle_sub_cancel_url;
  }

  get shortenedEmail(): string {
    return shortenEmail(this.email);
  }
}
