import { Component, OnDestroy, OnInit } from '@angular/core';
import { Account } from "../../models/account";
import { finalize, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { LicenseViewModel } from "../../view-models/license.viewmodel";
import { ApiService } from "../../services/api.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LicenseService } from "../../services/license.service";

@Component({
  selector: 'app-license-keys',
  templateUrl: './license-keys.component.html',
  styleUrls: ['./license-keys.component.scss']
})
export class LicenseKeysComponent implements OnInit, OnDestroy {

  account?: Account;
  licenseViewModels?: LicenseViewModel[];
  showCopiedHint = false;

  licenseToDelete?: LicenseViewModel;
  infoMessage?: string;
  errorMessage?: string;

  private subscription?: Subscription;

  constructor(private auth: AuthService,
              private api: ApiService,
              private license: LicenseService,
              private jwtHelper: JwtHelperService) {
  }

  ngOnInit() {
    this.subscription = this.auth.account.subscribe({
      next: account => {
        this.account = account;
        if (account) {
          this.licenseViewModels = account.activeLicenses.map(lic => new LicenseViewModel(lic, this.jwtHelper))
            // sort lexicographically (works because ISO8061 dates are designed to support it), newest first
            .sort((a, b) => b.license.creation_time.localeCompare(a.license.creation_time));
        } else {
          this.licenseViewModels = undefined;
        }
      }
    });
    this.auth.refreshAccount().then(() => {});
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

  copyKeyToClipboard(key: string): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(key)
        .then(() => {
          this.showCopiedHint = true;
          setTimeout(() => {
            this.showCopiedHint = false;
          }, 2500);
        })
        .catch((err) => {
          window.alert(`Failed to copy to clipboard: ${err.name}`); // TODO: modal
        });
    }
  }

  /**
   * Prompt user to confirm license deletion.
   */
  onDeleteLicense(license: LicenseViewModel) {
    const modalElem = document.getElementById('deleteLicenseKeyModal');
    if (modalElem === null) {
      return;
    }

    // show Bootstrap modal using JS api
    this.licenseToDelete = license;
    const modal = new (window as any).bootstrap.Modal(modalElem);
    modal.show();
  }

  /**
   * User confirmed license deletion.
   */
  confirmLicenseDeletion() {

    // dismiss modal
    const modalElem = document.getElementById('deleteLicenseKeyModal')
    if (modalElem === null) {
      return;
    }
    const modal = (window as any).bootstrap.Modal.getInstance(modalElem);
    modal?.hide();

    // and delete license key
    if (this.licenseToDelete) {
      this.api.deleteLicense(this.licenseToDelete.licenseId).pipe(
        finalize(() => this.licenseToDelete = undefined)
      ).subscribe({
        next: () => {
          this.errorMessage = undefined;
          this.infoMessage = 'Successfully deleted license key.';
          this.auth.refreshAccount();
        },
        error: err => {
          this.errorMessage = `Failed to delete license key: ${err.message}. Please try again and contact support if the issue persists`;
          this.infoMessage = undefined;
        }
      });
    }
  }
}
