import { Component } from '@angular/core';
import { AuthService } from "../../services/auth.service";
import { Account } from "../../models/account";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent {

  account$: Observable<Account | undefined>;

  constructor(private auth: AuthService) {
    this.account$ = auth.account;
  }

  openBeacon() {
    const account = this.auth.account.value;
    if (!account) {
      return;
    }

    const Beacon = (window as any).Beacon;
    Beacon('init', environment.helpScout.beaconId);
    const identityProperties = {
      email: account.email
    };
    Beacon('identify', {
      ...identityProperties, ...account.helpScoutProperties
    });
    Beacon('open');
  }
}
