import { Component, OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";
import { CanonicalService } from '../../services/canonical.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private canonical: CanonicalService) {
    // only injecting singleton service so it gets instantiated
  }

  ngOnInit(): void {
    if (environment.paddle.sandbox) {
      // see: https://developer.paddle.com/getting-started/c052e9e8d265f-working-with-the-paddle-sandbox
      (window as any).Paddle.Environment.set('sandbox');
    }
    (window as any).Paddle.Setup({
      vendor: parseInt(environment.paddle.vendorId)
    });

    // dynamic Plausible integration, only in production
    if (environment.production) {
      const scriptElem = document.createElement('script');
      scriptElem.dataset['domain'] = 'strich.io';
      scriptElem.src = '/js/script.tagged-events.js'; // proxied integration
      document.head.appendChild(scriptElem);
    }
  }
}
